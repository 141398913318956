import React, { Component } from 'react';
import { Panel } from 'react-bootstrap';
import GoogleStreetView from "../../../../components/GoogleStreetView";
import ErrorComponent from "../../../../components/Input/ErrorComponent";

class PrintStreetView extends Component {
  state = {status: 'display'};

  handleError = (msg) => {
    this.setState({status: 'notFound'});
  };

  handleLoaded = () => {
    this.setState({status: 'display'});
  };

  render () {
    const { address } = this.props.coordinates;

    if (this.state.status === 'display'){
      const header = `Street View for: ${address}`;

      return (
          <div>
            <Panel bsStyle="success">
              <Panel.Heading>
                <a href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`}
                   target="_blank"
                   rel="noopener noreferrer"
                >
                  {header}
                </a>
              </Panel.Heading>
              <Panel.Body>
                <div style={{
                  width: '600px',
                  height: '400px',
                  position: 'relative'
                }}>
                  <GoogleStreetView onError={this.handleError} onLoaded={this.handleLoaded} address={address} />
                </div>
              </Panel.Body>
            </Panel>
          </div>
      );
    } else if (this.state.status === 'notFound') {
      return (
          <ErrorComponent
              headerMessage={'Google doesn\'t have a Street View for this address.'}
              message={'You can refer to Aerial Map or Overview Map as alternatives.'}/>
      );
    } else {
      return (
          <Panel header="Street View">
            <h3>Loading...</h3>
          </Panel>
      );
    }
  }
}

export default PrintStreetView;
