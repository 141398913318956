import React from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Grid,
  Row,
  Col
} from 'react-bootstrap';
import scrollTo from 'scroll-to';
import {
  getLocation
} from '../../../utils/submissionUtils';

import {dashboard} from '../../../components/sideMenu';
import SideBar from '../../Navigation/SideBar';
import {
  removeLocation,
  updateTab
} from '../../../actions/dashboardActions';

class AddressTab extends React.Component{
  state = {
      showMenu: false
    };

  toggleMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu
    });
  };

  handleCloseTab = () => {
    const {
      submission_file,
      location_id
    } = this.props;

    this.props.updateTab(submission_file, 1);
    this.props.removeLocation(location_id);

    scrollTo(0, 0, { duration: 500 });
  };

  render () {
    const {
      location_id
    } = this.props;

    const location = getLocation(location_id);

    if (!location) {
      return <div></div>;
    }

    const active_tab_section = dashboard.subsections[location.active_section];

    const responsive_class = (this.state.showMenu) ? 'sidebar-container' : 'sidebar-container hidden';
    const button_class = (this.state.showMenu) ? 'on' : 'off';

    return (
      <section className="tab addressTab">
        <div className="main-dashboard-container">

          <Grid>
            <Row>

              <Col md={2} className="hidden-sm hidden-xs">
                <SideBar handleCloseTab={this.handleCloseTab} location={location}/>
              </Col>

              <div className={ `${responsive_class} hidden-md hidden-lg`} >
                <SideBar handleCloseTab={this.handleCloseTab} location={location}/>
              </div>

              <Col
                xs={12}
                md={10}
                // Tab component will be re-rendered every time 'key' or submission_file.id changes
                // this is done to make sure that all components are refreshed when new data is loaded
                key={this.props.submission_file.id}
                className="content-container">
                <Button bsStyle="primary" className={ `hidden-md hidden-lg ${button_class}` } onClick={this.toggleMenu}>
                </Button>
                {
                  active_tab_section.component({
                    location: location,
                    handleCloseTab: this.handleCloseTab
                  })
                }
              </Col>
            </Row>
          </Grid>

        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return ({
    submission_file: state.dashboard.submission_file
  });
};

export default connect(mapStateToProps, {removeLocation, updateTab})(AddressTab);
