import React from 'react';
import {
  Grid,
  Row,
  Col,
} from 'react-bootstrap';
import BaseSection from '../../../../components/baseSection';
import ViolentCrimeElement  from './ViolentCrimeElement';
import PropCrimeElement     from './PropCrimeElement';
import PopulationElement     from './PopulationElement';

const CrimePop = (props) => {
  const {
    location
  } = props;

  return (
    <BaseSection
      title="Crime & Population"
      handleCloseTab={props.handleCloseTab}>

      <Grid className="component-container">
         <Row>
          <Col xs={6}>
            <PropCrimeElement barHeight={30} location={location}/>
          </Col>

          <Col xs={6}>
            <ViolentCrimeElement barHeight={30} location={location}/>
          </Col>

        </Row>

        <Row>
          <Col xs={6}>
            <PopulationElement location={location}/>
          </Col>

          <Col xs={6}>

          </Col>
        </Row>


      </Grid>
    </BaseSection>
  );
};

export default CrimePop;
