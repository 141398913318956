import { updateLocation } from '../../actions/dashboardActions';
import { getLocation } from '../../utils/submissionUtils';

const getAvg = (props) => {
  return new Promise((resolve, reject) => {

    const location = getLocation(props.location_id);
    if (location.weather_us && !props.forceRequest) {
      resolve(location.weather_us);
      return;
    }

    const resp = { success: true, results: { earthquake_index: 1.81, flood_count: 300, hail_count: 300, hurricane_count: 300 } };

    props.dispatch(
      updateLocation(
        props.location_id,
        Object.assign(location, { weather_us: resp }))
    );

    resolve(resp);

  });
};


export default getAvg;
