import React, { Component } from 'react';
import { Panel } from 'react-bootstrap';

class PrintFlood extends Component {

  render () {
    const {
      position,
      stage,
      address
    } = this.props.coordinates;

    const extent = (position.longitude - 0.00215) + ',' +  (position.latitude - 0.002) + ',' +  (position.longitude + 0.00215) + ',' + (position.latitude + 0.002);
    const marker = position.longitude + ';' +  position.latitude;
    const src = 'https://www.arcgis.com/apps/Embed/index.html?webmap=42328623e5eb452e91dbb05c24a560fe&amp;&extent=' + extent + '&zoom=true&scale=true&legendlayers=true&marker=' + marker;

    const floodMap = () => {
      return React.createElement('iframe', { className: 'fema', src: src, width: '600', height: '400', position: 'relative' });
    };

    if (stage === 'display'){
      const header = `Flood Map for: ${address}`;

      return (
        <Panel bsStyle="success">
          <Panel.Heading>
              <a href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`}
                 target="_blank"
                 rel="noopener noreferrer"
              >
                  {header}
              </a>
          </Panel.Heading>
          <Panel.Body>
            {floodMap()}
          </Panel.Body>
        </Panel>
      );
    } else {
      return (
        <Panel>
          <Panel.Heading>Flood Map</Panel.Heading>
          <Panel.Body>
            <h3>Loading...</h3>
          </Panel.Body>
        </Panel>
      );
    }
  }
}

export default PrintFlood;
