import React from 'react';
import SearchResult from '../SearchResult';
import {
  Grid,
  Col,
  Button
} from 'react-bootstrap';

const SearchResults = (props) => {
  const {
    results,
    source,
    page,
    page_size
  } = props;

  return (
    <Grid>
      <Col xs={12} md={10} className="results">

        {
          (() => {
            return results.map((result, idx) => {
              return (idx >= page * page_size && idx < (page + 1) * page_size) ?
                <SearchResult key={idx} source={source} result={result}/> : '';
            });
          })()
        }

        <div className="more-previous">
          <Button bsStyle="info" onClick={props.handleChangePage}>More results</Button>
        </div>

      </Col>
    </Grid>
  );
};

export default SearchResults;
