import React from 'react';

const ErrorComponent = (props) => {
  const headerMessage = props.headerMessage ? props.headerMessage : 'Something Went Wrong.';

  return <div className="error_container">
    <h3>{headerMessage}</h3><p>{props.message}</p>
  </div>;
};

export default ErrorComponent;
