import React from 'react';
import { connect } from 'react-redux';
import {dashboard} from '../../../components/sideMenu';
import {
  getCurrentLocation,
  getLocation
} from '../../../utils/submissionUtils';
import {
  updateLocation
} from '../../../actions/dashboardActions';

class SideBarItem extends React.Component {
  handleClick = (e) =>  {
    e.preventDefault();

    this.props.handleItemClick(this.props.item);
  };

  render () {
    const { item, active_tab_section } = this.props;
    const activeClass = (active_tab_section.route === item.route) ? 'active' : '';

    return <a className={ activeClass }
          href="/"
          onClick={ this.handleClick }>
          { item.name }
        </a>;
  }
}

class SideBar extends React.Component {
  handleItemClick = (item) => {
    const location_id = getCurrentLocation().id;
    this.props.updateLocation(location_id, { 'active_section': item.route });
  };

  buildSubsection = (subsection) => {
    const location_id = getCurrentLocation().id;
    const active_tab_section = dashboard.subsections[
      getLocation(location_id).active_section
    ];

    return (
      <section key={subsection.route}>
        <label>{ subsection.name }</label>

        <ul>
          {
            (() => {
              return Object.keys(subsection.items).map((item) => {
                return <li key={item}>
                  <SideBarItem
                    handleItemClick={ this.handleItemClick }
                    item={ subsection.items[item] }
                    active_tab_section={ active_tab_section }/>
                </li>;

              });
            })()
          }
        </ul>
      </section>
    );
  };

  render () {
    return <div className="sidebar">
      <div className="menu_section">
        {
          (() => {
            return Object.keys(dashboard.sections).map((section) => {
              return <div key={section}>{ this.buildSubsection(dashboard.sections[section]) }</div>;
            });
          })()
        }

      </div>

      <div className="business_section">
        <p><b>{this.props.location.name}</b></p><br/>
        <p className="address_1">{this.props.location.address},</p>
        <p className="address_2">{this.props.location.city}, {this.props.location.state} {this.props.location.zipcode}</p>
      </div>

    </div>;
  }
}

const mapStateToProps = (state) => {
  return {
    submission_file: state.dashboard.submission_file
  };
};

export default connect(mapStateToProps, {updateLocation})(SideBar);
