import React from 'react';
import { connect } from 'react-redux';
import SearchItem from '../SearchItem';
import { addLocation, removeLocation } from '../../../../../actions/dashboardActions';

const MAX_LOCATIONS = 20;

class SearchItems extends React.Component{
  handleUseSearchItem = (business) => {
    if (business.active) {
      this.props.removeLocation(business.id);
    } else {
      const locations = this.props.submission_file.locations;

      if (locations.length === MAX_LOCATIONS) {
        alert(`Maximum of ${MAX_LOCATIONS} locations`);

        return;
      }

      this.props.addLocation(business);
    }

    business.active = !business.active;
    this.props.handleUpdateBusinesses();
  };

  render () {
    const {
      display_page
    } = this.props;

    const range = {
      start: 6 * display_page,
      end: 6 * (display_page + 1)
    };

    return (
      <div className="business-items">

        {
          (() => {
            return this.props.businesses.map((b, idx) => {
              if (idx >= range.start && idx < range.end){
                return (<SearchItem
                    key={ idx }
                    business={ b }
                    handleSelectItem={this.handleUseSearchItem}/>);
              } else {
                return null;
              }
            });
          })()
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return ({
    submission_file: state.dashboard.submission_file
  });
};

export default connect(mapStateToProps,{addLocation, removeLocation})(SearchItems);
