import React from 'react';
import { connect } from 'react-redux';
import BaseSection from '../../../../components/baseSection';
import LoadingComponent from '../../../../components/Display/LoadingComponent';
import ErrorComponent from '../../../../components/Display/ErrorComponent';
import MapComponent from '../../../../components/Display/MapComponent';
import SearchInput from '../../../../components/Input/SearchInputComponent';
import getGeoCode from '../../../../services/Google/getGeoCode';

class AerialMapSection extends React.Component{
  constructor (props) {
    super(props);
    const {
      address,
      city,
      state,
      zipcode
    } = this.props.location;

    this.state = {
      address: `${address} ${city}, ${state} ${zipcode}`,
      position: {latitude: 0, longitude: 0},
      status: 'loading'
    };
  }

  handleUpdateAddress = (address) => {
    const {
      dispatch
    } = this.props;

    this.setState({
      status: 'loading'
    });

    const location_id = this.props.location.id;

    getGeoCode({ forceRequest: true,  address: address, location_id, dispatch })
      .then((geoCode) => {

        this.setState({
          status: 'display',
          address: address,
          position: geoCode.position
        });

      })
      .catch((message) => {

        this.setState({
          status: 'error',
          error_message: message
        });

      });
  };

  handleChangeAddress = (address) => {

    this.setState({
      address
    });
  };

  handleResetAddress = () => {
    const {
      address,
      city,
      state,
      zipcode
    } = this.props.location;

    this.handleUpdateAddress (`${address} ${city}, ${state} ${zipcode}`);
  };

  loadData = () => {
    const location_id = this.props.location.id;
    const dispatch = this.props.dispatch;

    return new Promise((resolve, reject) => {

      getGeoCode({ address: this.state.address, location_id, dispatch })
        .then((geoCode) => {

          this.setState({
            status: 'display',
            position: geoCode.position
          });

          resolve();
        }).catch((reason) => {
          console.error("AerialMap Error: ", reason);
      });

    });
  };

  setDisplay = () => {
    switch (this.state.status){
      case ('loading'):
        return <LoadingComponent/>;

      case ('display'):
        return <MapComponent
          position={ this.state.position }
          initial_zoom={18}
          default_display={'SATELLITE'}
          containerElement={
            <div style={{ height: 500, width: '100%' }} />
          }
          mapElement={
            <div style={{ height: 500, width: '100%' }} />
          }
        />;

      case ('error'):
      default:
        return <ErrorComponent message={this.state.error_message}/>;
    }
  };

  render () {
    return (
      <BaseSection
        loadData={ this.loadData }
        title="Aerial Map"
        handleCloseTab={this.props.handleCloseTab}>

        <SearchInput
          title={ '' }
          input={ this.state.address }
          button_name="Search"
          handleChangeInput={ this.handleChangeAddress }
          handleUpdateInput={ this.handleUpdateAddress }
          handleResetInput={ this.handleResetAddress }
        />

      {
        this.setDisplay()
      }

      </BaseSection>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    submission_file: state.dashboard.submission_file
  };
};

export default connect(mapStateToProps)(AerialMapSection);
