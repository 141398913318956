import React from 'react';
import {connect} from 'react-redux';
import BaseSection from '../../../../components/baseSection';
import SearchInput from '../../../../components/Input/SearchInputComponent';
import LoadingComponent from '../../../../components/Display/LoadingComponent';

import KeywordSearchCards from './KeywordSearchCards';
import KeywordResults from './KeywordResults';
import KeywordResultsEmpty from './KeywordResultsEmpty';
import _ from 'lodash';
import search from '../../../../services/Google/search';
import keywordSearch from '../../../../services/KeywordSearch';
import getBusiness from '../../../../services/BusinessSearch/getBusiness';

class KeywordSearch extends React.Component {
    state = {
        active: 0,
        search_results: [],
        scan_url: '',
        stage: 'results',
        category_collapse: {},
        scan_results: {}
    };

    generateScanResults = (occurrences) => {
        const scan_results = {
            total_keywords: 0,
            total_pages: 0,
            total_occurences: 0,
            found_keywords: [],
            found_pages: [],
            categories: []
        };

        occurrences.forEach((occurrence) => {
            scan_results.total_occurences++;

            if (scan_results.found_pages.indexOf(occurrence.url) === -1) {
                scan_results.total_pages++;
                scan_results.found_pages.push(occurrence.url);
            }

            if (scan_results.found_keywords.indexOf(occurrence.keyword) === -1) {
                scan_results.total_keywords++;
                scan_results.found_keywords.push(occurrence.keyword);
            }

            let cat_entry_idx = _.findIndex(scan_results.categories, {name: occurrence.category});
            if (cat_entry_idx === -1) {
                scan_results.categories.push({
                    name: occurrence.category,
                    total_keywords: 0,
                    total_occurrences: 0,
                    found_keywords: [],
                    links: []
                });

                cat_entry_idx = scan_results.categories.length - 1;
            }

            // first get the category entry for this occurrence
            const cat_entry = scan_results.categories[cat_entry_idx];

            // add to category overall occurrence
            cat_entry.total_occurrences++;

            // add to category keyword not already within
            if (cat_entry.found_keywords.indexOf(occurrence.keyword) === -1) {
                cat_entry.total_keywords++;
                cat_entry.found_keywords.push(occurrence.keyword);
            }

            // search within the links of the cat_entry an entry with this url
            let link_entry_idx = _.findIndex(cat_entry.links, {url: occurrence.url});
            if (link_entry_idx === -1) {
                cat_entry.links.push({url: occurrence.url, occurrences: []});
                link_entry_idx = cat_entry.links.length - 1;
            }

            // add the text and keyword of this entry to this link
            cat_entry.links[link_entry_idx].occurrences.push({
                text: occurrence.text,
                keyword: occurrence.keyword,
                line: occurrence.line
            });

        });

        // now just make sure that each link section is in order
        scan_results.categories.forEach((cat) => {
            cat.links.forEach((link, idx) => {
                cat.links[idx].occurrences = _.sortBy(link.occurrences, ['line']);
            });

        });

        return scan_results;
    };

    scanPage = (url) => {
        return new Promise((resolve, reject) => {

            keywordSearch(url)
                .then((resp) => {

                    if (resp) {
                        const occurrences = resp;
                        const category_collapse = {};
                        const scan_results = this.generateScanResults(occurrences);

                        scan_results.categories.forEach((category) => {
                            category_collapse[category.name] = false;
                        });

                        this.setState({
                            scan_results,
                            category_collapse
                        });

                        resolve({
                            scan_results,
                            category_collapse
                        });
                    } else {
                        reject();
                    }

                })
                .catch(() => {
                    reject();
                });
        });
    };

    loadData = () => {
        const {
            location
        } = this.props;

        return new Promise((resolve, reject) => {
            const term = `${location.name} ${location.city} ${location.state}`;
            const promises = [
                search({term, dispatch: this.props.dispatch, location_id: location.id}),
                getBusiness({
                    location: location,
                    location_id: location.id,
                    source: location.source,
                    dispatch: this.props.dispatch
                })
            ];

            // do google search for current business
            Promise.all(promises).then((resp) => {

                if (resp[0].success) {

                    const websites = (resp[1].business_url) ?
                        _.concat(
                            [{
                                link: resp[1].business_url,
                                snippet: 'Source Website',
                                title: resp[1].name
                            }],
                            resp[0].results
                        ) :
                        resp[0].results;

                    const search_results = websites.map((obj, idx) => {
                        const display_url = obj.link.replace('http://', '').replace('https://', '');

                        return ({
                            url: obj.link,
                            display_url: (display_url.length > 15) ? (display_url.substr(0, 15) + '...') : display_url,
                            title: (obj.title.length > 22) ? (obj.title.substr(0, 22) + '...') : obj.title,
                            snippet: (obj.snippet.length > 80) ? (obj.snippet.substr(0, 80) + '...') : obj.snippet
                        });
                    });

                    this.setState({
                        search_results,
                        active: 0,
                        scan_url: search_results[0] ? search_results[0] : ''
                    });

                    this.scanPage(
                        this.state.scan_url.url
                    ).then(() => {
                        this.setState({state: 'results'});
                        resolve();
                    })
                    .catch((err) => {
                        this.setState({state: 'empty'});
                        resolve();
                    });

                } else {
                    reject(resp[0].message);
                }

            });

        });
    };

    handleChangeAddress = (url) => {
        this.setState({
            scan_url: {...this.state.scan_url, url}
        });
    };

    handleResetAddress = () => {
      const {search_results} = this.state;
      this.setState({
          active: 0,
          scan_url: search_results[0] ? search_results[0] : ''
      })
    };

    handleScanAddress = () => {
        this.setState({
            stage: 'loading'
        });

        this.scanPage(
            this.state.scan_url.url
        ).then((resp) => {
            const {
                category_collapse,
                scan_results
            } = resp;

            this.setState({
                active: 0,
                scan_results,
                category_collapse,
                stage: 'results'
            });

        }).catch( (error) => {
            this.setState({
                stage: 'empty'
            });
        });
    };

    handleCollapse = (category_name) => {
        const category_collapse = Object.assign({}, this.state.category_collapse);
        category_collapse[category_name] = !category_collapse[category_name];

        this.setState({
            category_collapse
        });
    };

    setDisplay = () => {
        const {
            stage,
            scan_results,
            category_collapse
        } = this.state;

        switch (stage) {
            case ('results'):
                return <KeywordResults
                    handleCollapse={this.handleCollapse}
                    category_collapse={category_collapse}
                    scan_results={scan_results}
                />;

            case ('loading'):
                return <LoadingComponent/>;

            case ('empty'):
            default:
                return <KeywordResultsEmpty/>;
        }
    };

    render() {
        const {
            scan_url,
            active,
            search_results
        } = this.state;

        return (
            <BaseSection
                loadData={this.loadData}
                className="keywordSearch"
                title="Keyword Search"
                handleCloseTab={this.props.handleCloseTab}>

                <div className="keyword-search-container">

                    <KeywordSearchCards
                        active={active}
                        handleChangeAddress={this.handleChangeAddress}
                        search_results={search_results}
                    />

                    <SearchInput
                        input={scan_url.url}
                        button_name={'Scan'}
                        className="site_inputs"
                        handleUpdateInput={this.handleScanAddress}
                        handleChangeInput={this.handleChangeAddress}
                        handleResetInput={this.handleResetAddress}
                    />

                </div>

                <div className="container keyword-results-container">
                    {
                        this.setDisplay()
                    }
                </div>

            </BaseSection>
        );
    }
}

export default connect()(KeywordSearch);
