import React from 'react';
import {
  withGoogleMap,
  GoogleMap,
  Marker
} from 'react-google-maps';

const GoogleMapsAPI = window.google.maps;
const LatLng = GoogleMapsAPI.LatLng;

export default withGoogleMap(class MapComponent extends React.Component {
    render () {
      const position = new LatLng(this.props.position.latitude, this.props.position.longitude);

      return <GoogleMap
        ref="map"
        className="map-area"
        defaultZoom={this.props.initial_zoom}
        defaultCenter= { position }
        mapTypeId= {window.google.maps.MapTypeId[this.props.default_display.toUpperCase()]}>
        <Marker
          position={ position }
          key={'loc_1'}
          defaultAnimation={0}
          />
      </GoogleMap>;
    }
});
