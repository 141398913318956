import parser from 'parse-address';
import _ from 'lodash';

export function translateBusinessSnippet (params) {
  switch (params.source){
    case ('Yelp'):
      return ({
        key: params.data.id,
        name: params.data.name,
        address: params.data.location.address1,
        city: params.data.location.city,
        state: params.data.location.state,
        zipcode: params.data.location.zip_code,
        source: 'yelp',
        active: false
      });
    case ('YellowPages'):
      return ({
        key: params.data.businessKey,
        name: params.data.businessName,
        address: params.data.address,
        city: params.data.city,
        state: params.data.state,
        zipcode: params.data.zip,
        source: 'yellowPages',
        active: false
      });
    case ('GooglePlaces'):
      const parse_address = parser.parseLocation(params.data.formatted_address);
      const checkAddressParse = (item) => (item) ? (item + ' ') : '';

      if (parse_address === null)
        return (null);

      return ({
        key: params.data.place_id,
        name: params.data.name,
        address: _.trim(checkAddressParse(parse_address.number) + checkAddressParse(parse_address.prefix) + checkAddressParse(parse_address.street) + checkAddressParse(parse_address.type)),
        city: parse_address.city,
        state: parse_address.state,
        zipcode: parse_address.zip,
        source: 'googlePlaces',
        active: false
      });
    default:
      return null;
  }
}

export function translateBusinessFull (params) {

  switch (params.source){
    case ('yelp'):
      return ({
        key: params.data.id,
        name: params.data.name,
        address: params.data.location.display_address.join(' '),
        city: params.data.location.city,
        state: params.data.location.state_code,
        zipcode: params.data.location.postal_code,
        coordinates: params.data.location.coordinate,
        categories: params.data.categories,
        phone: params.data.display_phone,
        rating: params.data.rating,
        photos: [params.data.image_url],
        review_count: params.data.review_count,
        business_url: null,
        search_url: params.data.url,
        source: 'yelp'
      });
    case ('yellowPages'):
      if (params.data.address === null)
        return (null);

      return ({
        key: params.data.businessKey,
        name: params.data.businessName,
        address: params.data.address,
        city: params.data.city,
        state: params.data.state,
        zipcode: params.data.zip,
        categories: [],
        phone: params.data.telephone,
        coordinates: params.data.coordinate,
        business_url: params.data.website,
        search_url: `http://www.yellowpages.com/${params.data.businessKey}`,
        source: 'yellowPages'
      });
    case ('googlePlaces'):
      const place = params.data.result;

      const parse_address = parser.parseLocation(place.formatted_address);
      const checkAddressParse = (item) => (item) ? (item + ' ') : '';

      const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
      if (parse_address === null) {
        return (null);

      }

      return ({
        key: place.id,
        name: place.name,
        address: _.trim(checkAddressParse(parse_address.number) + checkAddressParse(parse_address.prefix) + checkAddressParse(parse_address.street) + checkAddressParse(parse_address.type)),
        city: parse_address.city,
        state: parse_address.state,
        zipcode: parse_address.zip,
        categories: place.types,
        phone: place.formatted_phone_number,
        coordinates: { longitude: place.geometry.location.lng, latitude: place.geometry.location.lat },
        business_url: place.website,
        search_url: place.url,
        photos: (place.photos) ? place.photos.map((p_obj) => {
          return (`https://maps.googleapis.com/maps/api/place/photo?key=${apiKey}&maxheight=${p_obj.height}&maxwidth=${p_obj.width}&photoreference=${p_obj.photo_reference}`);
        }) : undefined,
        rating: place.rating,
        source: 'googlePlaces'
      });

    case ('BBB'):
      return (params.data);

    case ('User Input'):
      return ({
        key: params.data.id,
        name: params.data.name,
        address: params.data.address,
        coordinates: { longitude: 0, latitude: 0 },
        city: params.data.city,
        state: params.data.state,
        zipcode: params.data.zipcode,
        source: 'User Input'
      });

    default:
      return null;
  }
}
