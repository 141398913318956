// react components
import React from 'react';
import {connect} from 'react-redux';
import BaseElement from '../../../../../components/baseElement';
import BarChartComponent from '../../../../../components/BarChart';
import getByZipcode from '../../../../../services/Crime/getByZipcode';
import getByState from '../../../../../services/Crime/getByState';
import getAvg from '../../../../../services/Crime/getAvg';

class ViolentCrimeElement extends React.Component {
    state = {
        zipcode_average: 0,
        state_average: 0,
        us_average: 0
    };

    loadData = async () => {
        const {zipcode, state} = this.props.location;
        const location_id = this.props.location.id;
        const dispatch = this.props.dispatch;

        const response = await Promise.all([
            getByZipcode({zipcode, location_id, dispatch}),
            getByState({state, location_id, dispatch}),
            getAvg({location_id, dispatch})
        ]);

        if (!response[0] || !response[1] || !response[2]) {
            return 'Violent crime data not found for this location.';
        }

        this.setState({
            zipcode_average: Math.round(response[0].violent_crime),
            state_average: Math.round(response[1].violent_crime),
            us_average: Math.round(response[2].violent_crime)
        });
    };

    render() {
        const {
            location
        } = this.props;

        const {
            zipcode_average,
            state_average,
            us_average
        } = this.state;

        return (
            <BaseElement loadData={this.loadData} title="Violent Crime" custom_class="violentcrime">
                <div>
                    <div className="barChartElement">
                        <p>{location.zipcode} zip</p>
                        <BarChartComponent active={true} max_value={100} value={zipcode_average}/>
                    </div>

                    <div className="barChartElement">
                        <p>{location.state} state average</p>
                        <BarChartComponent active={false} max_value={100} value={state_average}/>
                    </div>

                    <div className="barChartElement">
                        <p>U.S. average</p>
                        <BarChartComponent active={false} max_value={100} value={us_average}/>
                    </div>
                </div>

                <br/>
                <p>On a scale from 1 (low crime) to 100 (high crime) <br/>
                </p>

                <a
                    className="source"
                    href={`https://www.bestplaces.net/crime/zip-code/${location.state}/${location.city}/${location.zipcode}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    Sperling’s Best Places
                </a>
            </BaseElement>
        );
    }

}

export default connect()(ViolentCrimeElement);
