import _ from 'lodash';

export function saveToCache (key, data) {
  return sessionStorage.setItem(key, JSON.stringify(data));
}

export function loadFromCache (key) {
  return JSON.parse(sessionStorage.getItem(key));
}

export function clearFromCache (key) {
  return sessionStorage.removeItem(key);
}

export function inCache (key) {
  const item = sessionStorage.getItem(key);

  return (!_.isNull(item));
}
