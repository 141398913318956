// react components
import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

// tools
import StarRatingComponent from 'react-star-rating-component';

// components
import BaseElement from '../../../../../components/baseElement';

// services
import getBusiness
  from '../../../../../services/BusinessSearch/getBusiness';

class SummaryElement extends React.Component{
  state = {
      business: {}
    };

  conditionalElement = (condition, element, else_element = '') => {
    return (condition) ? element : else_element;
  }

  getSearchTerm = () => {
    const {
        location
      } = this.props;

    return `${location.name} ${location.address} ${location.city} ${location.state} ${location.zipcode}`;
  };

  loadData = () => {
    const {
      location,
    } = this.props;

    return new Promise((resolve, reject) => {

      const promises = [
        getBusiness({
          location: this.props.location,
          location_id: this.props.location.id,
          source: location.source,
          dispatch: this.props.dispatch
        })
      ];

      Promise.all(promises)
      .then((resp) => {

        this.setState({
          business: resp[0]
        });

        resolve();
      })
      .catch(() => {
        reject('Sorry, we could not load business.');
      });

    });

  };

  render () {
    const {
      business
    } = this.state;

    return (
      <BaseElement loadData={ this.loadData } custom_class="summaryElement" title="Directory Listing">

        <div>
          <h2>{ business.name }</h2>
          <p>{ business.address }</p>
          <p>{ business.city }, { business.state } { business.zipcode }</p>

          {
            this.conditionalElement(business.phone,
              <p>{ business.phone }</p>,
              <p className="link">No Phone # Provided</p>
            )
          }

          {
            this.conditionalElement(business.rating,
              <div className="link rating" >
                <StarRatingComponent
                  name="rating"
                  editing={false}
                  starCount={5}
                  renderStarIcon={(index, value) => {
                    return <span className={index <= value ? 'fa fa-star' : 'fa fa-star-o'} />;
                  }}
                  renderStarIconHalf={() => <span className="fa fa-star-half-full" />}
                  value={business.rating}
                />
              </div>,
              <p className="link">No Rating Provided</p>
            )
          }

          {
            this.conditionalElement(business.review_count,
              <div className="link reviews" >
                {business.review_count} Total Reviews
              </div>,
              <p className="link">No Reviews Provided</p>
            )
          }

          {
            this.conditionalElement(business.business_url,
              <div>
                <p className="link">Source Website:</p>
                <a onClick={this.handleBusinessUrlClick} href={business.business_url} target="_blank" rel="noopener noreferrer" className="link">
                  {business.business_url}
                </a>
              </div>,
              <p className="link">No Website Provided</p>
            )
          }

          <a
            className="source"
            href={business.search_url}
            target="_blank"
            rel="noopener noreferrer">
            { business.source }
          </a>
        </div>

      </BaseElement>
    );
  }

}

export default connect()(SummaryElement);
