import thunkMiddleware from 'redux-thunk';

import {
    applyMiddleware,
    combineReducers,
    createStore
} from 'redux';

import {composeWithDevTools} from 'redux-devtools-extension';

import reducers from './reducers';

const store = createStore(
    combineReducers({
        ...reducers
    }),
    composeWithDevTools(
        applyMiddleware(
            thunkMiddleware
        )
    )
);
export default store;
