import React from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import BaseSection from '../../../../components/baseSection';
import SummaryElement from './SummaryElement';
import MapElement from './MapElement';
import GoogleElement from './GoogleElement';

class OverviewSection extends React.Component{
  state = {
    business: {}
  };

  render () {
    const {
      location
    } = this.props;

    return (
      <BaseSection
        title="Overview"
        handleCloseTab={this.props.handleCloseTab}>

        <Grid className="component-container-overview">
          <Row>
            <Col xs={12} md={6}>
              <SummaryElement location={location}/>
            </Col>

            <Col xs={12} md={6}className="map-container">
              <MapElement location={location}/>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={6}>
              <GoogleElement location={location}/>
            </Col>
          </Row>
        </Grid>

      </BaseSection>
    );
  }
}

export default OverviewSection;
