import React, { Component } from 'react';
import { Panel } from 'react-bootstrap';

class PrintAerial extends Component {

  render () {
    const {
      position,
      stage,
      address
    } = this.props.coordinates;
    const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
    const map_url =
      `https://www.google.com/maps/embed/v1/view?center=${position.latitude},${position.longitude}&zoom=18&key=${apiKey}&maptype=satellite`;

    if (stage === 'display'){
      const header = `Aerial Map for: ${address}`;

      return (
        <Panel bsStyle="success">
          <Panel.Heading>
              <a href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`}
                 target="_blank"
                 rel="noopener noreferrer"
              >
                  {header}
              </a>
          </Panel.Heading>
          <Panel.Body>
            <iframe width="600" height="400" src= { map_url } title="Print Aerial"></iframe>
          </Panel.Body>
        </Panel>
      );
    } else {
      return (
        <Panel>
          <Panel.Heading>Aerial Map</Panel.Heading>
          <Panel.Body>
            <h3>Loading...</h3>
          </Panel.Body>
        </Panel>
      );
    }
  }
}

export default PrintAerial;
