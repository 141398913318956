import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import content from '../../../content';
import { Alert, Button, Grid, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';

class PrintNavigationBar extends Component {

  handlePrint = () => {
    window.print();
  };

  render () {
    const backHelp = <Tooltip id="tooltip">Return To The Dashboard</Tooltip>;
    const printHelp = <Tooltip id="tooltip">Print Document</Tooltip>;

    return (
      <Alert bsStyle="info">
        <Grid className="print-container">
          <Row>
             <Col xs={6}>
              <Link to="/">
                <span className="title">
                  <b>{content.title}</b>
                </span>
                <span className="logo">Argo Logo</span>

              </Link>
            </Col>

            <Col xs={5} xsOffset={1}>
              <div className="align-print-button">

                <OverlayTrigger placement="bottom" overlay={printHelp}>
                  <Button
                    className="back-button"
                    bsStyle="info"
                    onClick={this.handlePrint}>
                    <span className="glyphicon glyphicon-print"></span>
                  </Button>
                </OverlayTrigger>

                <OverlayTrigger placement="bottom" overlay={backHelp}>
                  <Link to="/">
                    <Button
                      className="print-section-button"
                      bsStyle="info">
                      <span className="glyphicon glyphicon-triangle-left"></span>
                    </Button>
                  </Link>
                </OverlayTrigger>
              </div>
            </Col>
          </Row>
          &nbsp;
        </Grid>
      </Alert>

    );
  }
}

export default PrintNavigationBar;
