import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import SearchItems from './SearchItems';
import LoadingComponent from '../../../../components/Display/LoadingComponent';

class SearchInput extends React.Component{
  render () {
    return (
      <section id="business-section" className="business-section">
        <div className="container">
          <h2>Step 2: Choose your locations</h2>
          <hr />
          {
            (() => {
              switch (this.props.stage) {
                case ('loading'):
                  return <LoadingComponent />;
                case ('input'):
                  return <div>
                     <h3>Search above to see locations</h3>
                   </div>;
                case ('selection'):
                  return <div>
                    <SearchItems display_page={this.props.display_page} handleUpdateBusinesses={ this.props.handleUpdateBusinesses } businesses={this.props.businesses} dispatch={this.props.dispatch}/>
                    <Button onClick={this.props.handleMoreResults} bsStyle="info" className="more-results">More results</Button>
                  </div>;
                default:
                  return null;
              }
            })()

          }
        </div>
      </section>
    );
  }
}

export default connect()(SearchInput);
