import searchGoogle from '../../../../services/Google/search';
import searchNews from '../../../../services/Google/searchNews';
import searchLaw from '../../../../services/Google/searchLaw';

const sources = [
  {
    name: 'Google',
    service: searchGoogle,
    convert: (data) => {
      return ({
        name: data.title,
        link: data.link,
        snippet: data.snippet,
      });
    }
  },
  {
    name: 'Google News',
    service: searchNews,
    convert: (data) => {

      return ({
        name: data.name,
        link: data.link,
        source: data.source,
        post_date: data.post_date,
        snippet: data.snippet,
      });
    }
  },
  {
    name: 'Casetext',
    service: searchLaw,
    convert: (data) => {
      return ({
        name: data.title,
        link: data.link,
        snippet: data.snippet,
      });
    }
  }
];

export default sources;
