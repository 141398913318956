import React from 'react';
import {
  ButtonGroup,
  Button
} from 'react-bootstrap';

import classnames from 'classnames';

class SearchInput extends React.Component{

  render () {
    return <div className={classnames('map-inputs', this.props.className)}>

        {
          (() => {
            if (this.props.title !== '') {
              return <label>
                  {this.props.title}
                </label>;
            }
          })()
        }

        <div className="input-container">
          <input
            ref="input"
            onChange={
              () => this.props.handleChangeInput && this.props.handleChangeInput(this.refs.input.value)
            }
            value={this.props.input}
            type="text"
          />


          <ButtonGroup className="map-buttons">
            <Button
              bsStyle="info"
              onClick={
                () => this.props.handleResetInput && this.props.handleResetInput()
              }>
              Reset
            </Button>

            <Button
              bsStyle="info"
              onClick={
                () => this.props.handleUpdateInput(this.refs.input.value)
              }>
              {
                this.props.button_name
              }
            </Button>
          </ButtonGroup>

        </div>
      </div>;
  }
}

export default SearchInput;
