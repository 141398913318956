import React from 'react';
import classnames from 'classnames';
import {
  Row,
  Col,
  Button
} from 'react-bootstrap';
import LoadingComponent from './Display/LoadingComponent';
import ErrorComponent from './Display/ErrorComponent';

class BaseSection extends React.Component{
  state = {
      status: 'loading'
    };

  getDisplay = () => {
    switch (this.state.status) {
      case ('loading'):
        return (<LoadingComponent />);
      case ('display'):
        return this.props.children;
      case ('error'):
      default:
        return (<ErrorComponent message={ this.state.error_message }/>);
    }
  };

  componentDidMount () {
    if (!this.props.loadData) {
      this.setState({
        status: 'display'
      });
    } else {
      this.props.loadData()
        .then(() => {
          this.setState({
            status: 'display'
          });
        })
        .catch((error_message) => {

          this.setState({
            status: 'error',
            error_message: error_message
          });
        });

    }
  }

  handleClose = () => {
    this.props.handleCloseTab();
  };

  handlePrint = () => {
    window.print();
  };

  render () {
    const {
      title,
      className
    } = this.props;

    return <div className={classnames('content', className)}>
      <div className="content-header">
        <div className="header">
          <Row>
            <Col xs={10}>
              <h1>{ title } |
                <Button
                  className="print-button"
                  bsStyle="info"
                  onClick={this.handlePrint}>
                  <span className="glyphicon glyphicon-print"></span>
                </Button>
              </h1>
            </Col>

            <Col xs={2}>
              <div className="text-right">
                <Button
                  className="close-button"
                  bsStyle="info"
                  onClick={this.handleClose}>

                  <span className="glyphicon glyphicon-remove"></span>
                </Button>
              </div>
            </Col>

          </Row>
        </div>
        {
          this.getDisplay()
        }
      </div>
    </div>;
  }
}

export default BaseSection;
