import React from 'react';
import Highlighter from 'react-highlight-words';
import classnames from 'classnames';
import plural from 'plural';
import _ from 'lodash';

import {
  Row,
  Col,
  Button,
  Collapse,
} from 'react-bootstrap';

class KeywordResultsCategory extends React.Component{
  displayOccurrences = (occurrences) => {
    return occurrences.map((snippet, idx) => {
      const keywordRegex = new RegExp('(^|\\s)' + snippet.keyword + '(\\s|$)');

      return (
        <p key={snippet.keyword + snippet.line + idx}>
          <Highlighter
          highlightClassName='highlight'
          searchWords={[keywordRegex]}
          textToHighlight={snippet.text}
          />
        </p>
      );
    });
  };

  render () {
    const {
      name,
      total_keywords,
      total_occurrences,
      links
    } = this.props.category;

    const {
      is_open
    } = this.props;

    return (
      <div>
        <Row className="keyword-results-section">
         <Col xs={1}>
            <Button onClick={this.props.handleCollapse} className={classnames([{ 'collapse_open': is_open }, { 'collapse_close': !is_open }])}></Button>
          </Col>
          <Col xs={11}>
            <p><b>{name}</b></p>
            <p>{total_occurrences} occurrences of {total_keywords} { plural('keyword', total_keywords) } found</p>
          </Col>


        </Row>

        <Row>
         <Collapse in={ is_open }>

          <Col xs={11} xsOffset={1}>

           <div className="keyword-well">

             {
               (() => {
                 return links.map((link, idx) => {
                   return (<div className="keyword-link-block" key={link.url}>
                    <a href={link.url} target="_blank" rel="noopener noreferrer">{link.url}</a>
                    <br />
                    {
                      this.displayOccurrences(link.occurrences)
                    }
                  </div>);
                 });
               })()
             }
          </div>
          </Col>
        </Collapse>
       </Row>

     </div>
    );
  }
}

class KeywordResults extends React.Component{
  render () {
    const {
      scan_results,
      category_collapse
    } = this.props;

    return <div>
      <h3>{ scan_results.total_keywords } keywords found on { scan_results.total_pages } { plural('page', scan_results.total_pages) } </h3>
      {
        (() => {
          return _.sortBy(scan_results.categories, 'name').map((category, idx) =>
            <KeywordResultsCategory handleCollapse={() => { this.props.handleCollapse(category.name); }} key={category.name} is_open={category_collapse[category.name]} category={ category }/>
          );
        })()
      }
    </div>;
  }
}

export default KeywordResults;
