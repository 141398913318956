import React from 'react';
import {connect} from 'react-redux';
import {
    showPreviousSubmissions,
    showSubmissionModal,
    updateSubmissionFile,
} from '../../../actions/dashboardActions';

import {
    Button
} from 'react-bootstrap';

import submissionSchema from '../../../config/submission_file_schema.json';

import ValidationInput from '../../Input/ValidationInput';
import apiGateway from "../../../api/apiGatewayWrapper";

class NewSubmissionModal extends React.Component {
    state = {
        submission_id: '',
        submission_id_validation: {status: 'default', message: ''}
    };

    handleCreateSubmission = () => {
        const {
            submission_id
        } = this.state;

        if (submission_id === '') {
            this.setState({
                submission_id_validation: {
                    status: 'error',
                    message: 'Please Enter a submission number to proceed.'
                }
            });
        } else {

            apiGateway.getSubmission(submission_id)
                .then((resp) => {

                    if (resp.data !== "") {
                        this.setState({
                            submission_id_validation: {
                                status: 'error',
                                message: 'This submission number is already taken.'
                            }
                        });

                    } else {
                        const newSubmission = JSON.parse(JSON.stringify(submissionSchema));
                        newSubmission.submission_id = submission_id;

                        this.props.closeModal(newSubmission);
                    }

                }).catch((error) => {
                    console.error("New Submission Modal: ", error);
                });

        }
    };


    handleInput = (params) => {
        const new_state = {};
        new_state[params.name] = params.value;
        this.setState(new_state);
    };

    searchPrevSubs = () => {
        return this.props.showPreviousSubmissions(true);
    };

    openSub = () => {
        const {
            submission_id
        } = this.state;

        if (submission_id === '') {
            this.setState({
                submission_id_validation: {
                    status: 'error',
                    message: 'Please enter a valid submission number'
                }
            });
        } else {

            this.setState({
                submission_id_validation: {
                    status: 'default',
                    message: 'Searching...'
                }
            });

            apiGateway.getSubmission(submission_id)
                .then((resp) => {

                    if (resp && resp.data && resp.data !== "") {
                        this.props.updateSubmissionFile(resp.data);
                        // to remove new submission modal
                        this.props.showSubmissionModal(false);

                    } else {
                        this.setState({
                            submission_id_validation: {
                                status: 'error',
                                message: 'This submission id is not found.'
                            }
                        });
                    }
                }).catch((error) => {
                console.error("New Submission Modal: ", error);
            });
        }
    };

    cancelSubmitModal = () => {
        this.props.cancelModal();
    };

    render() {
        const {
            showCancel
        } = this.props;

        return (
            <section className='newSubmissionModal'>

                <h2>Enter a submission number</h2>

                <ValidationInput
                    id="submission_id"
                    type="text"
                    value={this.state.submission_id}
                    validation_status={this.state.submission_id_validation.status}
                    validation_message={this.state.submission_id_validation.message}
                    handleInputChange={this.handleInput}
                />

                <div className="text-center">

                    <Button bsStyle="primary" onClick={this.handleCreateSubmission}>
                        Create
                    </Button>

                    <Button bsStyle="info" onClick={() => this.openSub()}>
                        Open
                    </Button>

                    <Button bsStyle="warning" onClick={() => this.searchPrevSubs()}>
                        Search
                    </Button>

                    <Button disabled={!showCancel} bsStyle="info" onClick={this.cancelSubmitModal}>
                        Cancel
                    </Button>

                </div>
            </section>
        );
    }

}


export default connect(null, {
    showPreviousSubmissions,
    showSubmissionModal,
    updateSubmissionFile
})(NewSubmissionModal);
