import React from 'react';
import classNames from 'classnames';

class ValidationInput extends React.Component {
  onChange = () => {
    this.props.handleInputChange({ name: this.props.id, value: this.refs.input.value });
  };

  render () {
    const value = this.props.value || '';

    return (
      <div className="input_container" id={this.props.id}>
        {
          (() => {
            return (this.props.label) ? <label>{this.props.label}</label> : <label></label>;
          })()
        }

        <input ref="input" value={value} onChange={this.onChange} className={ this.props.validation_status } type={this.props.type} />
        <span className="validation_icon"></span>
        <span className={ classNames(['validation_text', this.props.validation_status])}>{ this.props.validation_message }</span>
      </div>
    );
  }

}

export default ValidationInput;
