import apiGateway from '../../api/apiGatewayWrapper';

const getBusinesses = (prop) => {
  const { name, location } = prop;
  const params = { name: encodeURIComponent(name), location: encodeURIComponent(location) };

  return apiGateway.getAllBusinesses(
    params
  )
  .then((resp) => {
    return (resp.data);
  })
  .catch((error) => {
    console.error(error);

    return ({ success: false, message: 'Internal Error' });
  });
};

export default getBusinesses;
