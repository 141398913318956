import React from 'react';
import { connect } from 'react-redux';
import {
  translateBusinessSnippet
} from '../../../utils/searchEngineUtils';
import DialogBox from '../../../components/Modal/DialogBox';

import SearchResults from './SearchResults';
import SearchInput from './SearchInput';
import UseInputModal from './UseInputModal';

import {
  addLocation,
  updateTab,
  saveSubmissionFile
} from '../../../actions/dashboardActions';
import getBusinesses from '../../../services/BusinessSearch/getBusinesses';

class SearchTab extends React.Component{
  constructor(props) {
    super(props);
    const current_search = this.getCurrentSearch();

    const {
      display_page,
      businesses
    } = current_search;

    this.state = {
      display_page,
      num_pages: 6,
      businesses,
      input_modal_show: false
    };
  }

  getCurrentSearch = () => {
    const {
      current_search
    } = this.props.submission_file;

    return (current_search);
  };

  updateCurrentSearch = (params) => {
    Object.keys(params).forEach(key=>this.props.submission_file.current_search[key]=params[key]);
    this.props.saveSubmissionFile(this.props.submission_file, false);

    this.setState({});
  };

  addToSearchHistory = () => {
    const {
      current_search
    } = this.props.submission_file;

    this.props.submission_file.search_history.push({...current_search});
    this.props.saveSubmissionFile(this.props.submission_file);
  };

  handleSearch = () => {
    const {
      name,
      location
    } = this.getCurrentSearch();

    if (name === '' || location === '')
      return;

    this.updateCurrentSearch({ stage: 'loading' });

    getBusinesses({
      name,
      location
    }).then((resp) => {

      if (resp.success) {

        const businesses = resp.results.all_results.map(
          (item) => translateBusinessSnippet({
            source: item.source,
            data: item
          })).filter((item) => {
            return (item && item.city && item.state && item.zipcode);
          })
          .map((item, idx) => {
            return Object.assign({ card_index: idx }, item);
          });

        this.updateCurrentSearch({ stage: 'selection', businesses });
        this.addToSearchHistory();
      } else {

        this.updateCurrentSearch({ stage: 'error' });
      }
    });
  };

  handleChange = (params) => {
    this.updateCurrentSearch({ name: params.name, location: params.location });
  };

  handleClear = () => {
    if (this.getCurrentSearch().businesses.length === 0) {
      this.updateCurrentSearch({ name: '', location: '' });
    }

    this.updateCurrentSearch({ stage: 'input', businesses: [] });
  };


  handleOpenModal = () => {
    this.setState({
      input_modal_show: true
    });
  };

  handleCloseManuelModal = (business) => {

    this.props.addLocation(business);

    this.handleCancelManualModal();
  };

  handleCancelManualModal = () => {
    this.setState({
      input_modal_show: false
    });
  };

  handleMoreResults = () => {
    const {
      display_page,
      businesses
    } = this.getCurrentSearch();

    const page = ((display_page + 1) * this.state.num_pages < businesses.length) ? (display_page + 1) : 0;
    this.updateCurrentSearch({ display_page: page });
  };

  handleUpdateBusinesses = () => {
    this.updateCurrentSearch({ businesses: this.getCurrentSearch().businesses });
  };

  componentWillMount () {
  }

  render () {
    const {
      name,
      location,
      stage,
      businesses,
      display_page
    } = this.getCurrentSearch();

    return (
      <section className={`tab searchTab ${stage}_stage`}>

        <div className="main-search-container">
          <SearchInput
            name={ name }
            location={ location }
            handleChange={this.handleChange}
            handleClear={this.handleClear}
            handleSearch={this.handleSearch}
            handleOpenModal={this.handleOpenModal}
          />

          <SearchResults
            businesses={businesses}
            stage={stage}
            display_page={display_page}
            handleUpdateBusinesses={this.handleUpdateBusinesses}
            handleMoreResults={this.handleMoreResults}
            />
        </div>

        <DialogBox show={ this.state.input_modal_show } custom_class="useInputModalContainer">
          <UseInputModal
            name={ name }
            location={ location }
            handleCancel={ this.handleCancelManualModal }
            handleClose={ this.handleCloseManuelModal}
          />
        </DialogBox>

      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return ({
    submission_file: state.dashboard.submission_file
  });
};

export default connect(mapStateToProps, {
  addLocation,
  updateTab,
  saveSubmissionFile
})(SearchTab);
