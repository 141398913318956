import React from 'react';
import addCommas from 'add-commas';
import lerp from 'lerp';
import _ from 'lodash';

class BarChartComponent extends React.Component{

  isInt = (value) => {
    const er = /^-?[0-9]+$/;
    return er.test(value);
  };

  render () {
    const {
      active,
      max_value,
      value
    } = this.props;


    const width = 70 * (value / max_value);
    const min_value = 1;

    const bar_style = {
      width: `${ (width < min_value) ? min_value : width }%`,
      backgroundColor: (active) ? '#0d3e67' : '#9b9b9b'
    };

    const bar_label_style = {
      left: lerp(30, 105, _.clamp((value / max_value) + 0.5, 0, 1))
    };

    const display_value = (this.isInt(value)) ? addCommas(value) : value;

    return <div style={bar_style} className="bar_chart">
      <span className="bar_label" style={bar_label_style} >{display_value}</span>
    </div>;
  }
}

export default BarChartComponent;
