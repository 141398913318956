import { v4 as uuid } from 'uuid';
import apiGateway from '../api/apiGatewayWrapper';
import {dashboard} from '../components/sideMenu';
import _ from 'lodash';
import {
    saveToCache,
    clearFromCache
} from '../utils/dataCache';

import {
    getLocation
} from '../utils/submissionUtils';

// services

export const UPDATE_TAB_SECTION = 'UPDATE_SECTION';
export const SHOW_SUBMISSION_MODAL = 'SHOW_SUBMISSION_MODAL';
export const CLEAR_SUBMISSION_FILE = 'CLEAR_SUBMISSION_FILE';
export const UPDATE_SUBMISSION_FILE = 'UPDATE_SUBMISSION_FILE';
export const SHOW_PREVIOUS_SUBMISSIONS = 'SHOW_PREVIOUS_SUBMISSIONS';
export const HIDE_PREVIOUS_SUBMISSIONS = 'HIDE_PREVIOUS_SUBMISSIONS';

// actions
export function showSubmissionModal(show_submission_modal) {

    return {
        type: SHOW_SUBMISSION_MODAL,
        show_submission_modal
    };
}

export function addLocation(location) {
    // defines the section a tab loads intially after creation
    const initial_section = dashboard.sections.business_review.items.overview.route;

    return function (dispatch, getState) {
        const submission_file = getState().dashboard.submission_file;
        const locations = submission_file.locations;

        location.id = uuid();
        location.active_section = initial_section;
        locations.push(location);

        dispatch(saveSubmissionFile({...submission_file, locations}, true));
        dispatch(updateTab(submission_file,locations.length+1));
    };
}

export function removeLocation(location_id) {

    return function (dispatch, getState) {
        const submission_file = getState().dashboard.submission_file;
        const locations = _.filter(submission_file.locations, ((l) => (l.id !== location_id)));

        dispatch(saveSubmissionFile({...submission_file, locations}, true));
    };
}

export function updateLocation(location_id, modification) {
    return function (dispatch, getState) {
        const submission_file = Object.assign({}, getState().dashboard.submission_file);
        const location_index = _.findIndex(submission_file.locations, {id: location_id});
        const locations = submission_file.locations;

        locations[location_index] = Object.assign(
            getLocation(location_id), modification
        );

        dispatch(saveSubmissionFile({...submission_file, locations}, true));
    };
}

export function updateSubmissionFile(sub_file) {
    if (!sub_file) {
        clearFromCache('submission_file');
    } else {
        saveToCache('submission_file', sub_file);
    }

    return {
        type: UPDATE_SUBMISSION_FILE,
        submission_file: sub_file
    };
}

export const createSubmissionFile = (submission_file) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {

            dispatch(updateSubmissionFile(submission_file));
            dispatch(showSubmissionModal(false));

            apiGateway.createSubmission(submission_file)
                .then((resp) => {
                    resolve(resp.data);
                })
                .catch((error) => {
                    console.log(error);
                    resolve({success: false, message: 'Internal Error'});
                });
        } );
    };
};

export function saveSubmissionFile(submission_file, save_to_server = false) {
    return function (dispatch) {

        return new Promise((resolve, reject) => {
            dispatch(updateSubmissionFile(submission_file));

            if (save_to_server) {
                apiGateway.updateSubmission(submission_file.submission_id, submission_file)
                    .then((resp) => {
                        resolve(resp.data);
                    })
                    .catch((error) => {
                        console.error(error);
                        resolve({success: false, message: 'Internal Error'});
                    });
            }
        });
    };
}

export function deleteSubmissionFile(submission_id) {

    return new Promise((resolve, reject) => {

        apiGateway.deleteSubmission(submission_id)
            .then((resp) => {
                resolve(resp.data);
            })
            .catch((error) => {
                console.error(error);
                resolve({success: false, message: 'Internal Error'});
            });

    });
}

export function updateTab(submission_file, tab) {
    return function (dispatch) {
        Object.assign(submission_file, {current_tab: tab});
        dispatch(saveSubmissionFile(submission_file));
    };
}

export function showPreviousSubmissions(show_submission_data) {
    return {
        type: SHOW_PREVIOUS_SUBMISSIONS,
        show_submission_data
    };
}
