import {loadFromCache} from './dataCache';
import _ from 'lodash';

export function getLocation (id) {
  const submission_file = loadFromCache('submission_file');
  if (submission_file && submission_file.locations) {
    return Object.assign({}, _.find(submission_file.locations, (location) => location.id === id));
  }

  return;
}

export function getCurrentLocation () {
  const submission_file = loadFromCache('submission_file');

  return submission_file.locations[submission_file.current_tab - 2];
}
