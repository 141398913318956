import React from 'react';
import Helmet from 'react-helmet';
import {connect} from 'react-redux';
import {Tabs, Tab} from 'react-bootstrap';
import DialogBox from '../../components/Modal/DialogBox';
import NewSubmissionModal from '../../components/Modal/NewSubmissionModal';
import PreviousSubmissions from '../../components/Modal/PreviousSubmissions';

import SearchTab from './SearchTab';
import AddressTab from './AddressTab';
import {
    showSubmissionModal,
    createSubmissionFile,
    updateTab,
    showPreviousSubmissions
} from '../../actions/dashboardActions';
import FeedbackImage from "./FeedbackImage";

class Dashboard extends React.Component {
    state = {
        viewPreviousSubmissions: true,
    };

    handleSelect = (tab) => {
        this.props.updateTab(this.props.submission_file, tab);
        this.forceUpdate();
    };

    handleSubmissionModalClose = (sub_info) => {
        this.props.createSubmissionFile(sub_info);
        this.forceUpdate();
    };

    handleSubmissionModalCancel = () => {
        this.props.showSubmissionModal(false);
    };

    loadSubmission = (submission_file) => {

        if (!submission_file || !submission_file.locations)
            return;

        return submission_file.locations.map((location, idx) =>
            <Tab
                tabClassName="tab tab-selection address-tabs"
                key={idx}
                location_idx={idx}
                location_id={location.id}
                eventKey={idx + 2}
                title={`Address ${idx + 1}`}>
                {
                    (() => {
                        return (submission_file.current_tab === idx + 2) ? <AddressTab location_id={location.id}/> :
                            <div></div>;
                    })()
                }

            </Tab>);
    };

    componentWillMount() {
        this.props.showPreviousSubmissions(false);

        if (!this.props.submission_file.submission_id) {
            this.props.showSubmissionModal(true);
        }
    }

    render() {
        const bodyLines = [
            'Below are templates for issues or feature requests. Delete whichever you do not need.',
            'Please feel free to add screenshots as attachments.',
            '',
            'ISSUE REPORT',
            '<Steps to reproduce>',
            '',
            '<Expected behavior>',
            '',
            '<Submission number>',
            '',
            'FEATURE REQUEST',
            '<Description>',
            '',
            '<Rationale>'
        ];
        const mailTo = `mailto:jira@argodigital.atlassian.net?cc=nick.morgan@argolimited.com&subject=<Enter your issue or feature request here>&body=${bodyLines.join('%0D')}`;


        const showCancel = this.props.submission_file.submission_id;
        const activeKey = (this.props.submission_file.current_tab === 0) ? 1 : this.props.submission_file.current_tab;

        return (
            <main className="dashboard">
                <Helmet title={'Dashboard'}/>

                <Tabs
                    activeKey={activeKey}
                    onSelect={this.handleSelect}
                    id="tabs"
                >
                    <Tab
                        tabClassName="tab-selection search-tab"
                        eventKey={1}
                        title="Add Locations">
                        <SearchTab/>
                    </Tab>

                    {
                        this.loadSubmission(this.props.submission_file)
                    }
                </Tabs>

                {!this.props.show_submission_data &&
                <DialogBox
                    handleClose={this.handleSubmissionModalClose}
                    show={this.props.show_submission_modal}>
                    <NewSubmissionModal showCancel={showCancel} closeModal={this.handleSubmissionModalClose}
                                        cancelModal={this.handleSubmissionModalCancel}/>
                </DialogBox>
                }

                {this.props.show_submission_data && <PreviousSubmissions/>}

                <div id="feedback-wrapper">
                    <a href={mailTo}>
                        <FeedbackImage />
                        <span id="feedback-text">Feedback</span>
                    </a>
                </div>
            </main>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        show_submission_modal: state.dashboard.show_submission_modal,
        submission_file: state.dashboard.submission_file,
        show_submission_data: state.dashboard.show_submission_data
    };
};

export default connect(mapStateToProps, {
    showSubmissionModal,
    createSubmissionFile,
    updateTab,
    showPreviousSubmissions
})(Dashboard);
