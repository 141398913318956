import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';

class PrintModal extends Component {
  render () {
    return (
      <Modal animation={true} show={this.props.showPrintModal}>
        <Modal.Header closeButton>
          <Modal.Title>Loading... :-)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Please wait momentarily for all maps to load before printing</h4>
        </Modal.Body>
      </Modal>
    );
  }
}

export default PrintModal;

