import React from 'react';
import Autocomplete from 'react-google-autocomplete';
import {
  Button,
  ButtonGroup,
  Panel,
  Grid,
  Row,
  Col
} from 'react-bootstrap';

class SearchInput extends React.Component{
  state = {name: '', location: ''};

  handlePlaceSelected = (params) => {
    const selectedName = this.refs.business_name.refs.input.value;
    const selection = {name: selectedName.substring(0, selectedName.indexOf(",")), location: params.formatted_address.replace(', USA', '')};
    this.props.handleChange(selection);
    this.setState(selection);
  };

  handleChange = () => {
    this.props.handleChange({
      name: this.refs.business_name.refs.input.value,
      location: this.refs.business_location.value
    });
    this.setState({name: this.refs.business_name.refs.input.value, location: this.refs.business_location.value});
  };

  render () {
    const {
      name,
      location
    } = this.state;

    return (<section id="search-section" className="search-section">
      <Grid>
        <Row className="search-container">
          <Col xs={8}>
            <h2>Step 1: Search for a Business</h2>
            <hr />

            <div className="input-group" id="business_name_group">
              <label>Business name</label>
              <Autocomplete
                types={['establishment']}
                ref="business_name"
                placeholder=''
                onPlaceSelected={this.handlePlaceSelected}
                onChange={this.handleChange}
                value={name || ''}
              />
            </div>

            <div className="input-group" id="business_location_group">
              <label>Location</label>
              <input type="text"
                ref="business_location"
                onChange={this.handleChange}
                value={location || ''}
              />
            </div>

            <div className="text-right">

              <ButtonGroup className="search-buttons">
                <Button onClick={this.props.handleClear} variant="outline-dark">
                  Clear
                </Button>

                <Button onClick={this.props.handleSearch} bsStyle="info">
                  Search
                </Button>
              </ButtonGroup>

            </div>

          </Col>
          <Col xs={4} style={{ padding: 0 }}>
            <Panel className="info-panel">

              <div className="instructions-group">
                <h4>How SmartSearch works</h4>
                <p>SmartSearch collects information from multiple databases to help you quickly confirm the details of an insurance application.</p>
              </div>

              <div className="use-my-input-group">
                <h4>No results?</h4>
                <p>If you don&#39;t see a match below,
                  you can edit your search or
                  use your original input.</p>

                <Button
                  className="input-button"
                  onClick={ this.props.handleOpenModal }
                  bsStyle="info">
                  Use Input
                </Button>
              </div>

            </Panel>
          </Col>
        </Row>
      </Grid>
    </section>);
  }
}

export default SearchInput;
