import React from 'react';
import Helmet from 'react-helmet';
import content from '../content';
import NavigationBar from './Navigation/NavigationBar';
import Dashboard from './Dashboard';
import '../sass/main.scss';
import {withAuth} from "@okta/okta-react";

class App extends React.Component {

    state = {authenticated: false};

    componentDidMount() {
        this.setFullStoryIdentity();
    }

    setFullStoryIdentity = async () => {
        if (!window.FS) {
            return;
        }

        const userProfile = await this.props.auth.getUser();
        console.log("FULL STORY ACTIVE", userProfile);
        const { email, name } = userProfile;
        window.FS.identify(email, {
            displayName: name,
            email: email,
            env: process.env.REACT_APP_DEPLOYMENT_ENVIRONMENT
        });
    };

    checkAuthentication = async () => {
        const authenticated = await this.props.auth.isAuthenticated();
        if (authenticated !== this.state.authenticated) {
            this.setState({ authenticated });
        }
    };

    componentDidUpdate() {
        console.timeLog("ComponentUpdate");
        this.checkAuthentication();
    }

    render() {
        if (this.state.authenticated === null) return null;

        const baseMeta = [
            {
                'name': 'description',
                'content': content.description
            }
        ];

        return (
            <div className='app'>
                <Helmet
                    titleTemplate={content.title}
                    meta={baseMeta}
                />
                <NavigationBar title={content.title}/>
                <Dashboard/>
            </div>
        );
    }
}

export default withAuth(App);
