import React from 'react';
import {connect} from 'react-redux';
import commaNumber from 'comma-number';
import BaseElement from '../../../../../components/baseElement';
import getByZipcode from '../../../../../services/Population/getByZipcode';

class PopulationElement extends React.Component {
    state = {
        population: 0,
        density: 0
    };

    loadData = async () => {
        const location_id = this.props.location.id;
        const {zipcode} = this.props.location;
        const {dispatch} = this.props;

        const response = await getByZipcode({zipcode, location_id, dispatch});

        if (!response.population) {
            return 'Population data not found for this location.';
        }

        this.setState({
            population: Math.round(response.population),
            density: Math.round(response.population_density)
        });
    };

    render() {
        const {
            location
        } = this.props;

        const {
            population,
            density
        } = this.state;

        return (
            <BaseElement loadData={this.loadData} title="Population" custom_class="propcrime">

                <h3>{commaNumber(population)} total population</h3>
                <h4>{commaNumber(density)}/sq mi density</h4>

                <a
                    className="source"
                    href={`https://www.bestplaces.net/people/zip-code/${location.state}/${location.city}/${location.zipcode}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    Sperling’s Best Places
                </a>
            </BaseElement>
        );
    }

}

export default connect()(PopulationElement);
