import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';

import {SecureRoute, Security, ImplicitCallback} from '@okta/okta-react';
import store from './store';
import history from './history';
import auth from './auth';
import {Router, Route} from 'react-router-dom';

import App from './containers/App';
import PrintPage from './containers/PrintPage';

ReactDOM.render(
    <Router history={history}>
        <Security auth={auth}>
            <Provider store={store}>
                <SecureRoute path='/' exact={true} component={App}/>
                <SecureRoute path='/print' component={PrintPage}/>
                {/*<SecureRoute path='*' component={ Http404 } />*/}
            </Provider>
            <Route path='/implicit/callback' component={ImplicitCallback}/>
        </Security>
    </Router>
    ,
    document.getElementsByClassName('app-container')[0]
);
