import React from 'react';

const LoadingComponent = () => {
  return <div className="loading_container">

      <div id="tout">
        <div>
          <div>
            <div>
            </div>
          </div>
        </div>
      </div>

    </div>;
};

export default LoadingComponent;
