import {updateLocation} from '../../actions/dashboardActions';
import {getLocation} from '../../utils/submissionUtils';
import apiGateway from "../../api/apiGatewayWrapper";

const getGeoCode = (props) => {
    return new Promise((resolve, reject) => {
        const location = getLocation(props.location_id);
        if (location.geocode && !props.forceRequest) {
            resolve(location.geocode);

            return;
        }

        return apiGateway.getGoogleGeoCode({address: encodeURIComponent(props.address)})
            .then((response) => {
                const geocode = response.data;

                if (geocode.status !== 'OK') {
                    console.error("GetGeoCodeError", response);
                    throw new Error();
                }

                const formated_geocode = {
                    address: geocode.results[0].formatted_address,
                    position: (geocode.status !== 'OK') ? {} : {
                        latitude: geocode.results[0].geometry.location.lat,
                        longitude: geocode.results[0].geometry.location.lng
                    }
                };

                if (!props.forceRequest) {
                    props.dispatch(
                        updateLocation(
                            props.location_id,
                            Object.assign(location, {geocode: formated_geocode}))
                    );
                }

                resolve(formated_geocode);
            })
            .catch((reason) => {
                console.error("getGeoCodeError: ", reason);
                reject('Sorry, we could not retrieve the coordinates of that address.');
            });


    });
};

export default getGeoCode;
