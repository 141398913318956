// react components
import React from 'react';
import { connect } from 'react-redux';

import BaseElement from '../../../../../components/baseElement';
import MapComponent from '../../../../../components/Display/MapComponent';

// services
import getGeoCode from '../../../../../services/Google/getGeoCode';

class MapElement extends React.Component{

  constructor (props) {
    super(props);
    const {
      address,
      city,
      state,
      zipcode
    } = this.props.location;

    this.state = {
      address: `${address} ${city}, ${state} ${zipcode}`,
      position: { latitude: 0, longitude: 0 }
    };
  }

  loadData = () => {
    const location_id = this.props.location.id;
    const dispatch = this.props.dispatch;

    return new Promise((resolve, reject) => {

      getGeoCode({ address: this.state.address, location_id, dispatch })
        .then((geoCode) => {
          this.setState({
            address: geoCode.address,
            position: geoCode.position
          });
          resolve();
        })
        .catch((message) => {
          reject(message);
        });

    });

  };

  render () {
    return (
      <BaseElement loadData={this.loadData} custom_class="map">

        <MapComponent
          position={ this.state.position }
          initial_zoom={18}
          default_display={'ROADMAP'}
          containerElement={
            <div style={{ height: 400, width: '100%' }} />
          }
          mapElement={
            <div style={{ height: 400, width: '100%' }} />
          }
        />

      </BaseElement>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    submission_file: state.dashboard.submission_file
  };
};

export default connect(mapStateToProps)(MapElement);
