import React, {Component} from 'react';
import {Modal, Button, ButtonGroup} from 'react-bootstrap';
import {connect} from 'react-redux';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import DialogBox from '../DialogBox';
import ConfirmationModal from '../ConfirmationModal';
import moment from 'moment/moment';

import {
    showSubmissionModal,
    updateSubmissionFile,
    deleteSubmissionFile,
    showPreviousSubmissions
} from '../../../actions/dashboardActions';

import apiGateway from "../../../api/apiGatewayWrapper";

class PreviousSubmissions extends Component {
    state = {
        selectedRow: null,
        searchTerm: '',
        sizePerPage: 5,
        currentPage: 1,
        totalAvailableRows: 0,
        submissions: [],
        show_confirmation_modal: false,
        loading: false
    };

    handleDelete = () => {
        deleteSubmissionFile(this.state.delete_item).then(() => {
            this.setState({
                submissions: this.state.submissions.filter((s) => (s.id !== this.state.delete_item)),
                show_confirmation_modal: false
            });
        });
    };

    handleCancelConfirmation = () => {
        this.setState({
            show_confirmation_modal: false
        });
    };

    handleDeletePrompt = (next, dropRowKeys) => {
        this.setState({
            show_confirmation_modal: true,
            delete_item: dropRowKeys[0]
        });
    };

    handleSearchSubmissions = () => {
        this.loadSubmissions(this.refs.searchInput.value);
    };

    loadSubmissions = (searchTerm, pageSize = 5, pageNum = 1) => {

        if(searchTerm === ''){
            return;
        }

        this.setState({
            currentPage: pageNum,
            sizePerPage: pageSize,
            searchTerm: searchTerm,
            submissions: [],
            loading: true
        });

        const getSubmissionsPromise = apiGateway.getSubmissions({
            term: searchTerm || '',
            size: pageSize,
            page: pageNum
        });

        getSubmissionsPromise.then((resp) => {
            const list = [];

            for (const item of resp.data.submissions) {
                let address = ' ';
                let businessName = ' ';
                if (item.locations[0]) {
                    address = `${item.locations[0].address} ${item.locations[0].city} ${item.locations[0].state}`;
                    businessName = item.locations[0].name;
                }

                list.push({
                    id: item.submission_id,
                    submission_id: item.submission_id,
                    user: item.email,
                    address,
                    businessName,
                    createdOn: moment(item.created_timestamp).format('MM/DD/YYYY'),
                    updatedOn: item.updated_timestamp ? moment(item.updated_timestamp).format('MM/DD/YYYY') : ''
                });
            }

            this.setState({
                submissions: list,
                loading: false,
                totalAvailableRows: resp.data.totalRecords
            });
        });
    };

    handleRowSelected = (row) => {
        this.setState({selectedRow: row})
    };

    handleOpen = () => {
        if(!this.state.selectedRow){
            return;
        }

        apiGateway.getSubmission(this.state.selectedRow.submission_id)
            .then((resp) => {
                if (resp) {
                    this.props.updateSubmissionFile(resp.data);
                    this.props.showSubmissionModal(false);
                    this.props.showPreviousSubmissions(false);
                }
            });
    };

    onPageChange = (page, sizePerPage) => {
        this.loadSubmissions(this.state.searchTerm, sizePerPage, page);
    };

    render() {
        const createCustomButtonGroup = props => {
            return (
                <ButtonGroup className='my-custom-class'>
                    { props.deleteBtn }
                    <button type='button'
                            className={ `btn btn-info` }
                            onClick={this.handleOpen}>
                        Open
                    </button>
                </ButtonGroup>
            );
        };

        const options = {
            onPageChange: this.onPageChange,
            sizePerPage: this.state.sizePerPage,
            page: this.state.currentPage,
            paginationShowsTotal: true,
            sizePerPageList: [ 5, 10, 15, 20 ],

            handleConfirmDeleteRow: this.handleDeletePrompt,
            btnGroup: createCustomButtonGroup,
            noDataText: (this.state.loading) ? 'Loading Data...' : 'Please enter search criteria to display.'
        };

        return (
            <div>
                <Modal animation={true} dialogClassName="search-modal" show={true}>
                    <Modal.Header closeButton onHide={() => this.props.showPreviousSubmissions(false)}>
                        <Modal.Title>Search Submissions</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>

                        <div className="searchBox">
                            <input className="searchInput" ref="searchInput" type="text"
                                   placeholder="Enter a valid user or submission number"/>
                            <Button className="searchButton" bsSize="xs" bsStyle="info"
                                    onClick={this.handleSearchSubmissions}>Search</Button>
                        </div>

                        <BootstrapTable
                            data={this.state.submissions}
                            hover={true}
                            condensed={true}
                            pagination={true}
                            options={options}
                            remote={ true }
                            fetchInfo={ { dataTotalSize: this.state.totalAvailableRows } }
                            deleteRow
                            selectRow={{mode: 'radio', clickToSelect: true, onSelect: this.handleRowSelected}}
                            headerStyle={{
                                background: '#3081b5',
                                color: '#ffffff',
                                'fontFamily': 'Lato',
                                'fontWeight': 'normal',
                                'fontSize': '12px'
                            }}
                        >
                            <TableHeaderColumn
                                dataField="id"
                                isKey={true}
                                width="50px"
                                hidden
                            />
                            <TableHeaderColumn
                                width="60px"
                                dataField="submission_id"
                                dataSort={true}
                            >Submission #</TableHeaderColumn>
                            <TableHeaderColumn
                                width="120px"
                                dataField="user"
                                dataSort={true}
                            >User</TableHeaderColumn>
                            <TableHeaderColumn
                                width="100px"
                                dataField="businessName"
                                dataSort={true}
                            >Business Name</TableHeaderColumn>
                            <TableHeaderColumn
                                width="120px"
                                dataField="address"
                                dataSort={true}
                            >Address</TableHeaderColumn>
                            <TableHeaderColumn
                                width="60px"
                                dataField="createdOn"
                                dataSort={true}
                                columnFilter={true}
                            >Created On</TableHeaderColumn>
                            <TableHeaderColumn
                                width="60px"
                                dataField="updatedOn"
                                dataSort={true}
                            >Updated On</TableHeaderColumn>
                        </BootstrapTable>
                    </Modal.Body>
                </Modal>

                <DialogBox
                    show={this.state.show_confirmation_modal}>
                    <ConfirmationModal
                        message="Are you sure you want to delete this item?"
                        confirm_message="Yes"
                        cancel_message="No"
                        handleConfirm={this.handleDelete}
                        handleCancel={this.handleCancelConfirmation}
                    />
                </DialogBox>

            </div>
        );
    }
}

export default connect(null, {
    showSubmissionModal,
    updateSubmissionFile,
    showPreviousSubmissions
})(PreviousSubmissions);
