import React from 'react';
import { connect } from 'react-redux';
import classNames       from 'classnames';

import {
  Button,
  ButtonGroup
} from 'react-bootstrap';
import BaseSection from '../../../../components/baseSection';

import LoadingComponent from '../../../../components/Display/LoadingComponent';

import SearchResults from './SearchResults';
import SearchError from './SearchError';

import {
  getCurrentLocation
} from '../../../../utils/submissionUtils';

import sources from './sources';

class SearchSection extends React.Component{
  constructor (props) {
    super(props);

    const current_location = getCurrentLocation();

    this.state = {
      current_source: 0,
      results: [],
      status: 'loading',
      page: 0,
      page_size: 4,
      term: `${current_location.name} ${current_location.city}`
    };
  }

  handleChangeSource = (source_idx) => {
    this.setState({
      status: 'loading'
    });

    this.setState({
      current_source: source_idx
    });
  };

  handleChangePage = () => {
    const {
      page,
      page_size,
      results
    } = this.state;

    this.setState({
      page: ((page + 1) * page_size > results.length) ? 0 : (page + 1)
    });
  };

  setDisplay = () => {
    const {
      status,
      page,
      page_size,
      results,
      current_source
    } = this.state;

    switch (status) {
      case ('loading'):
        this.loadResults();

        return <LoadingComponent />;

      case ('results'):
        return <SearchResults
          page={page}
          page_size={page_size}
          results={results}
          source={sources[current_source]}
          handleChangePage={this.handleChangePage}/>;

      case ('empty'):
      case ('error'):
      default:
        return <SearchError />;
    }
  };

  loadResults = () => {
    const { current_source } = this.state;
    const source_obj = sources[current_source];

    source_obj.service({
      term: this.state.term,
      location_id: getCurrentLocation().id,
      dispatch: this.props.dispatch
    }).then((resp) => {
      const results = resp.results || [];
      const status = (resp.success) ? (results.length === 0 ? 'empty' : 'results') : 'error';

      this.setState({
        status,
        page: 0,
        results: (resp.success) ? results.map(source_obj.convert) : []
      });

    });
  };

  componentWillMount () {
    this.loadResults();
  }

  render () {
    return (
      <BaseSection
        title="Search Results"
        handleCloseTab={this.props.handleCloseTab}>

       <ButtonGroup className="search-engines-group">
         {
           (() => {
             return sources.map((source, idx) => {
               return <Button
                        key={idx}
                        onClick={ () => { this.handleChangeSource(idx); } }
                        className={
                          classNames(
                            'btn-ghost-blue',
                            { 'active': idx === this.state.current_source })
                        }>
                        { source.name }
                      </Button>;
             });
           })()
         }
      </ButtonGroup>

      <div className="search">
        <h1>{ sources[this.state.current_source].name }</h1>
        {
          this.setDisplay()
        }
      </div>

      </BaseSection>
    );
  }
}

const mapStateToProps = (state) => {
  return ({
    submission_file: state.dashboard.submission_file
  });
};

export default connect(mapStateToProps)(SearchSection);
