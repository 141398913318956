import React from 'react';

class GoogleResult extends React.Component{
  render (){
    const {
      result
    } = this.props;
    const urlLink = result.link.match(/^http[s]*:\/\//) ? result.link  : `http://${result.link}`;

    return (<section>
        <a href={urlLink} target="_blank" rel="noopener noreferrer">{ result.title }</a>
        <p className="result-link">{result.link}</p>
        <p><strong>{result.snippet.substr(0, 120)}</strong></p>
      </section>
    );
  }
}

export default GoogleResult;
