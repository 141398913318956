import React, { Component } from 'react';
import PrintAerial from './PrintAerial';
import PrintStreetView from './PrintStreetView';
import PrintFlood from './PrintFlood';
import getGeoCode from '../../../services/Google/getGeoCode';
import { connect } from 'react-redux';

import {
  Row,
  Col
} from 'react-bootstrap';

class PrintCommonMaps extends Component {
  constructor (props) {
    super(props);

    this.state = ({
      coordinates: {
        stage: '',
        address: '',
        position: ''
      }
    });
  }

  componentDidMount = () => {
    const {
      location,
      dispatch
    } = this.props;

    const address = `${location.address} ${location.city}, ${location.state} ${location.zipcode}`;
    getGeoCode({
      address,
      location_id: location.id,
      dispatch
    }).then((geoCode) => {
      this.setState({
        coordinates: {
          stage: 'display',
          address,
          position: { latitude: geoCode.position.latitude, longitude: geoCode.position.longitude }
        }
      });
    });
  };

  render () {
    if (this.state.coordinates.stage === 'display')
    {
      return (
      <div>
        <Row>
          <Col xs={12} className="printWhole">
            <PrintStreetView coordinates={this.state.coordinates} key={this.state.coordinates.stage}/>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="printWhole">
            <PrintAerial coordinates={this.state.coordinates} key={this.state.coordinates.stage}/>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="printWhole">
            <PrintFlood coordinates={this.state.coordinates} key={this.state.coordinates.stage}/>
          </Col>
        </Row>
      </div>
      );
    } else {
      return (
        <h3>Loading...</h3>
      );
    }
  }
}


export default connect()(PrintCommonMaps);
