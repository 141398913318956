import React from 'react';
import { connect } from 'react-redux';
import BaseSection from '../../../../components/baseSection';
import SearchInput from '../../../../components/Input/SearchInputComponent';
import FemaItem from './FemaItem';
import getGeoCode from '../../../../services/Google/getGeoCode';

class FloodSection extends React.Component{
  constructor (props) {
    super(props);
    const {
      address,
      city,
      state,
      zipcode
    } = this.props.location;

    this.state = {
      address: `${address} ${city}, ${state} ${zipcode}`,
      position: { latitude: 0, longitude: 0 },
      stage: 'loading'
    };
  }

  handleUpdateAddress = (address) => {
    this.setState({
      stage: 'loading'
    });

    const location_id = this.props.location.id;
    const dispatch = this.props.dispatch;

    getGeoCode({ forceRequest: true,  address: address, location_id, dispatch })
      .then((geoCode) => {
        this.setState({
          stage: 'display',
          address: geoCode.address,
          position: geoCode.position
        });
      })
      .catch((message) => {
        this.setState({
          stage: 'error'
        });
      });

  };

  handleChangeAddress = (address) => {

    this.setState({
      address
    });
  };

  handleResetAddress = () => {
    const {
      address,
      city,
      state,
      zipcode
    } = this.props.location;

    this.handleUpdateAddress (`${address} ${city}, ${state} ${zipcode}`);
  };

  loadData = () => {
    return new Promise((resolve, reject) => {

      const location_id = this.props.location.id;
      const dispatch = this.props.dispatch;

      getGeoCode({ address: this.state.address, location_id, dispatch })
        .then((geoCode) => {

          this.setState({
            stage: 'display',
            address: this.state.address,
            position: geoCode.position
          });
          resolve();
        })
        .catch((message) => {
          reject(message);
        });

    });
  };

  render () {
    return (
      <BaseSection
        loadData={ this.loadData }
        title="Flood Map"
        handleCloseTab={this.props.handleCloseTab}>

        <SearchInput
          title={ '' }
          input={ this.state.address }
          button_name="Search"
          handleUpdateInput={ this.handleUpdateAddress }
          handleChangeInput={ this.handleChangeAddress }
          handleResetInput={  this.handleResetAddress }
        />

        <div className="map-area">
          <FemaItem position={this.state.position}/>
        </div>

      </BaseSection>
    );
  }
}

const mapStateToProps = (state) => {
  return ({
    submission_file: state.dashboard.submission_file
  });
};

export default connect(mapStateToProps)(FloodSection);
