import React, { Component } from 'react';
import { connect } from 'react-redux';
import commas from 'comma-number';
import {
  Table,
  Panel
} from 'react-bootstrap';
import getCrimeByState   from '../../../services/Crime/getByState';
import getCrimeAvg     from '../../../services/Crime/getAvg';
import getCrimeByZipcode from '../../../services/Crime/getByZipcode';
import getPopByZipcode from '../../../services/Population/getByZipcode';

class PrintPropCrime extends Component {
  constructor (props) {
    super(props);
    this.state = {
      property: {
        zipcode: '',
        state: '',
        avg: ''
      },
      violence: {
        zipcode: '',
        state: '',
        avg: ''
      },
      population: '',
      density: ''
    };
  }

  componentDidMount () {
    const {
      business
    } = this.props;

    const zipcode = business.zipcode;
    const state = business.state;
    const location_id = business.id;
    const dispatch = this.props.dispatch;
    Promise.all([
      getCrimeByZipcode({ zipcode, location_id, dispatch }),
      getCrimeByState({ state, location_id, dispatch }),
      getCrimeAvg({ location_id, dispatch }),
      getPopByZipcode({ zipcode, location_id, dispatch })
    ])
    .then((resp) => {

      this.setState({
        property: {
          zipcode: (resp[0]) ? Math.round(resp[0].property_crime) : 'not available',
          state: (resp[1]) ? Math.round(resp[1].property_crime) : 'not available',
          avg: (resp[2]) ? Math.round(resp[2].property_crime) : 'not available'
        },
        violence: {
          zipcode: (resp[0]) ? Math.round(resp[0].violent_crime) : 'not available',
          state: (resp[1]) ? Math.round(resp[1].violent_crime) : 'not available',
          avg: (resp[2]) ? Math.round(resp[2].violent_crime) : 'not available'
        },
        population: (resp[3]) ? Math.round(resp[3].population) : 'not available',
        density: (resp[3]) ? Math.round(resp[3].population_density) : 'not available'
      });
    });
  }

  render () {
    const {
      business
    } = this.props;

    const {
      property,
      violence,
      population,
      density
    } = this.state;

    return (
      <div>
        <Panel
          bsStyle="success"
          >
          <Panel.Heading>Crime & Property Data</Panel.Heading>
          <Panel.Body>
          <Table striped bordered condensed hover>
              <thead>
              <tr>
                  <th>By Region</th>
                  <th>Property Theft</th>
                  <th>Violence</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                  <td>{ business.zipcode } zip</td>
                  <td>*{ property.zipcode }</td>
                  <td>{ violence.zipcode }</td>
              </tr>
              <tr>
                  <td>{ business.state }</td>
                  <td>*{ property.state }</td>
                  <td>*{ violence.state }</td>
              </tr>
              <tr>
                  <td>U.S. average</td>
                  <td>*{ property.avg }</td>
                  <td>*{ violence.avg }</td>
              </tr>
              <tr>
                  <td>Pop Density</td>
                  <td>{ commas(density) }</td>
              </tr>
              <tr>
                  <td>Population</td>
                  <td>{ commas(population) }</td>
              </tr>
              </tbody>
          </Table>
          </Panel.Body>
          <Panel.Footer>*Score on a scale from 1 (low crime) to 100 (high crime). Data Souce: Sperling’s Best Places</Panel.Footer>
        </Panel>
      </div>
    );
  }

}

export default connect()(PrintPropCrime);
