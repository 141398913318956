import {Auth} from '@okta/okta-react';
import history from './history';

export default new Auth({
    history,
    issuer: 'https://login.argo-digital.com/oauth2/aus6btn2ogRGjp1tj2p7',
    client_id: process.env.REACT_APP_OKTA_CLIENTID,
    redirect_uri: window.location.origin + '/implicit/callback',
    scope: ['openid', 'profile', 'email']
});
