import React from 'react';

import Overview from '../containers/Dashboard/AddressTab/Overview';
import Search from '../containers/Dashboard/AddressTab/Search';
import KeywordSearch from '../containers/Dashboard/AddressTab/KeywordSearch';
import StreetView from '../containers/Dashboard/AddressTab/StreetView';
import AerialMap from '../containers/Dashboard/AddressTab/AerialMap';
import CrimePop from '../containers/Dashboard/AddressTab/CrimePop';
import WeatherOverview from '../containers/Dashboard/AddressTab/WeatherOverview';
import FloodSection from '../containers/Dashboard/AddressTab/Flood';

export const dashboard = {
  'subsections': {
    'overview': {
      'name': 'Overview',
      'route': 'overview',
      'component': (props) => {
        return <Overview {...props} />;
      }
    },

    'search_results': {
      'name': 'Search Results',
      'route': 'search_results',
      'component': (props) => {
        return <Search {...props} />;
      }
    },

    'keyword_search': {
      'name': 'Keyword Search',
      'route': 'keyword_search',
      'component': (props) => {
        return <KeywordSearch {...props} />;
      }
    },

    'street_view': {
      'name': 'Street View',
      'route': 'street_view',
      'component': (props) => {
        return <StreetView {...props} />;
      }
    },

    'aerial_map': {
      'name': 'Aerial Map',
      'route': 'aerial_map',
      'component': (props) => {
        return <AerialMap {...props} />;
      }
    },

    'crime_population': {
      'name': 'Crime & Pop',
      'route': 'crime_population',
      'component': (props) => {
        return <CrimePop {...props} />;
      }
    },

    'weather_overview': {
      'name': 'Weather Details',
      'route': 'weather_overview',
      'component': (props) => {
        return <WeatherOverview {...props} />;
      }
    },
    'flood_plain': {
      'name': 'Flood Data',
      'route': 'flood_plain',
      'component': (props) => {
        return <FloodSection {...props} />;
      }
    }

  },

  'sections': {
    'business_review': {
      'name': 'Business Review',

      'items': {
        'overview': {
          'name': 'Overview',
          'route': 'overview',
          'component': (props) => {
            return <Overview {...props} />;
          }
        },

        'search_results': {
          'name': 'Search Results',
          'route': 'search_results',
          'component': (props) => {
            return <Search {...props} />;
          }
        },

        'keyword_search': {
          'name': 'Keyword Search',
          'route': 'keyword_search',
          'component': (props) => {
            return <KeywordSearch {...props} />;
          }
        }
      }

    },

    'theft': {
      'name': 'Theft',

      'items': {
        'street-view': {
          'name': 'Street View',
          'route': 'street_view',
          'component': (props) => {
            return <StreetView {...props} />;
          }
        },

        'aerial_map': {
          'name': 'Aerial Map',
          'route': 'aerial_map',
          'component': (props) => {
            return <AerialMap {...props} />;
          }
        },

        'crime_population': {
          'name': 'Crime & Pop',
          'route': 'crime_population',
          'component': (props) => {
            return <CrimePop {...props} />;
          }
        }

      }
    },

    'weather': {
      'name': 'Weather',

      'items': {
        'weather_overview': {
          'name': 'Weather Details',
          'route': 'weather_overview',
          'component': (props) => {
            return <WeatherOverview {...props} />;
          }
        },
        'flood_plain': {
          'name': 'Flood Data',
          'route': 'flood_plain',
          'component': (props) => {
            return <FloodSection {...props} />;
          }
        }
      }
    }
  }
};
