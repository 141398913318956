import {updateLocation} from '../../actions/dashboardActions';
import {getLocation} from '../../utils/submissionUtils';

import {translateBusinessFull} from '../../utils/searchEngineUtils';

import apiGateway from '../../api/apiGatewayWrapper';

function getBusiness(props) {
    return new Promise((resolve, reject) => {
        const {
            location,
            source,
            location_id,
            dispatch
        } = props;


        const business_promise = (source !== 'User Input') ?
            getBusinessRequest({
                source,
                key: location.key,
                dispatch,
                location_id: location_id
            }) :
            new Promise((resolve, reject) => {
                resolve({success: true, results: location});
            });

        business_promise.then((resp) => {
            if (resp.success) {

                const business = translateBusinessFull({
                    source,
                    data: resp.results
                });

                resolve(business);
            } else {
                reject();
            }

        });

    });

}

const getBusinessRequest = (props) => {
    return new Promise((resolve, reject) => {
        const location = getLocation(props.location_id);
        const source = props.source.toLowerCase();

        if (location[source] && !props.forceRequest) {
            resolve(location[source]);

            return;
        }

        const sourceMap = {
            'yelp': {endpoint: apiGateway.getYelpBusiness, query: {key: props.key}},
            'yellowpages': {endpoint: apiGateway.getYellowpagesBusiness, query: {key: props.key}},
            'googleplaces': {endpoint: apiGateway.getGooglePlacesBusiness, query: {placeId: props.key}}
        };

        sourceMap[source].endpoint(sourceMap[source].query)
            .then((r) => {
                const resp = r.data;

                if (!props.forceRequest) {
                    const business_search_object = {};
                    business_search_object[source] = resp;

                    props.dispatch(
                        updateLocation(
                            props.location_id,
                            Object.assign(location, business_search_object))
                    );
                }

                resolve(resp);
            })
            .catch((error) => {
                console.error("GetBusiness: ", error);
                resolve({success: false, message: 'Internal Error'});
            });

    });

};


export default getBusiness;
