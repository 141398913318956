import React from 'react';
import {Grid} from 'react-bootstrap';

const SearchError = () => {
  return (
    <Grid>
      <h4>Sorry. No Results have been found for this business.</h4>
    </Grid>
  );
};

export default SearchError;
