import { updateLocation } from '../../actions/dashboardActions';
import { getLocation } from '../../utils/submissionUtils';
import apiGateway from '../../api/apiGatewayWrapper';

const getByState = (props) => {
  return new Promise((resolve, reject) => {

    const location = getLocation(props.location_id);
    if (location.crime_state && !props.forceRequest) {
      resolve(location.crime_state);
      return;
    }

    apiGateway.getCrimeState(
      { state: props.state.toUpperCase()  }
    ).then((r) => {
      const resp = r.data;

      if (!props.forceRequest) {
        props.dispatch(
          updateLocation(
            props.location_id,
            Object.assign(location, { crime_state: resp }))
        );
      }

      resolve(resp);
    }).catch(e => {
      reject('Failed to load server data');
    });

  });

};


export default getByState;
