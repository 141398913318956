import React from 'react';
import ReactDOM from 'react-dom';

import asyncLoading from 'react-async-loader';

class GoogleStreetView extends React.Component {

    constructor(props) {
        super(props);
        this.streetView = null;
        this.geocoder = new window.google.maps.Geocoder();
        this.directionsService = new window.google.maps.DirectionsService();
        this.googleLatLong = null;
    }

    initialize (canvas) {
        if (this.props.googleMaps && this.streetView == null) {
            this.streetView = new this.props.googleMaps.StreetViewPanorama(
                canvas
            );
        }
    }

    openAddress = () => {
        const {address} = this.props;

        this.geocoder.geocode({
            'address': address
        }, (results, status) => {
            if (status === window.google.maps.GeocoderStatus.OK) {
                this.googleLatLong = results[0].geometry.location;

                // find a Streetview location on the road
                const request = {
                    origin: address,
                    destination: address,
                    travelMode: window.google.maps.DirectionsTravelMode.DRIVING
                };
                this.directionsService.route(request, this.directionsCallback);
            }
        });
    };

    directionsCallback = (response, status) => {
        const sv = new window.google.maps.StreetViewService();
        if (status === window.google.maps.DirectionsStatus.OK) {
            const latlng = response.routes[0].legs[0].start_location;
            sv.getPanoramaByLocation(latlng, 50, this.processSVData);
        }
    };

    processSVData = (data, status) => {
        if(status !== 'ZERO_RESULTS') {
            this.streetView.setPano(data.location.pano);
            const heading = window.google.maps.geometry.spherical.computeHeading(data.location.latLng, this.googleLatLong);
            this.streetView.setPov({
                heading: heading,
                pitch: 0,
                zoom: 1
            });
            this.streetView.setVisible(true);

            this.props.onLoaded && this.props.onLoaded();
        }else{
            this.props.onError && this.props.onError("no results");
        }
    };

    componentDidMount () {
        this.initialize(ReactDOM.findDOMNode(this));
        this.openAddress();
    }

    componentDidUpdate () {
        this.initialize(ReactDOM.findDOMNode(this));
    }

    componentWillUnmount () {
        if (this.streetView) {
            window.google.maps.event.clearInstanceListeners(this.streetView);
        }
    }

    render () {
        return <div
            style = {{
                height: '100%'
            }}
        ></div>;
    }
}

function mapScriptsToProps (props) {
    const googleMapsApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
    return {
        googleMaps: {
            globalPath: 'google.maps',
            url: 'https://maps.googleapis.com/maps/api/js?key=' + googleMapsApiKey,
            jsonp: true
        }
    };
}

export default asyncLoading(mapScriptsToProps)(GoogleStreetView);
