// react components
import React from 'react';
import { connect } from 'react-redux';

import BaseElement from '../../../../../components/baseElement';
import BarChartComponent from '../../../../../components/BarChart';

// service
import getByState   from '../../../../../services/Weather/getByState';
import getByZipcode from '../../../../../services/Weather/getByZipcode';

class TornadoElement extends React.Component{
  state = {
      zipcode_average: 0,
      state_average: 0
    };

  loadData = () => {
    const { zipcode, state } = this.props.location;
    const location_id = this.props.location.id;
    const dispatch = this.props.dispatch;

    return new Promise((resolve, reject) => {

      Promise.all([
        getByZipcode({ zipcode, location_id, dispatch }),
        getByState({ state, location_id, dispatch })
      ]).then((resp) => {

        if (!resp[0] || !resp[1]) {
          reject('Violent crime data not found for this location.');

          return;
        }

        this.setState({
          zipcode_average: Math.round(resp[0].tornado_index),
          state_average: Math.round(resp[1].tornado_index)
        });

        resolve();
      });
    });
  };

  render () {
    const {
      location
    } = this.props;

    const {
      zipcode_average,
      state_average
    } = this.state;

    return (
      <BaseElement loadData={this.loadData} title="Tornado Index" subtitle="(1950-2010)" custom_class="flood">
        <div>
          <div className="barChartElement">
            <p>{ location.zipcode } zip</p>
            <BarChartComponent active={true} max_value={700} value={zipcode_average}/>
          </div>

          <div className="barChartElement">
            <p>{ location.state } state</p>
            <BarChartComponent active={false} max_value={700} value={state_average}/>
          </div>

        </div>
        <br/>
        <p>The tornado index value is calculated based on historical tornado events data using USA.com algorithms. A higher tornado index value indicates a higher likelyhood of an tornado.</p>
        <a
          className="source"
          href={`http://www.usa.com/${location.zipcode}-${location.state}-natural-disasters-extremes.htm`}
          target="_blank"
          rel="noopener noreferrer">
          USA.com
        </a>
      </BaseElement>
    );
  }

}

export default connect()(TornadoElement);
