import React from 'react';
import classnames from 'classnames';
import Slider from 'react-slick/lib';

const KeywordSearchCards = (props) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    className: 'slider'
  };

  return (
    <div className="search_results">
      <h3>Choose a page to scan </h3><br/>

      <Slider {...settings}>
        {
          (() => {
            return props.search_results.map((r, idx) => {

              return (
                <div
                  key={idx}
                  onClick={ () => { props.handleChangeAddress(r.url, idx, r.title); }}
                  className={classnames(['card', { 'active': props.active === idx }])}
                >
                  <p className="link"><a href={r.url}>{r.display_url}</a></p>
                  <p className="title"><b>{r.title}</b></p>
                  <p className="snippet">{r.snippet}</p>
                </div>
              );
            });
          })()
        }

      </Slider>

    </div>
  );
};

export default KeywordSearchCards;
