import { updateLocation } from '../../actions/dashboardActions';
import { getLocation } from '../../utils/submissionUtils';
import apiGateway from '../../api/apiGatewayWrapper';

const search = (props) => {
  return new Promise((resolve, reject) => {

    const location = getLocation(props.location_id);
    const { term } = props;

    if (location.search_google && !props.forceRequest) {
      resolve(location.search_google);

      return;
    }

    return apiGateway.getGoogle(
      { term }
    ).then((r) => {
      const resp = r.data;

      if (!props.forceRequest) {
        props.dispatch(
          updateLocation(
            props.location_id,
            Object.assign(location, { search_google: resp }))
        );
      }

      resolve(resp);
    }).catch((error) => {
      console.error(error);
      resolve({ success: false, message: 'Internal Error' });
    });

  });
};

export default search;
