import React from 'react';
import {Col} from 'react-bootstrap';

const SearchResult = (props) => {
  const {
    result,
    source
  } = props;

  const link_text = (source.name === 'Google News') ? result.source + ' - ' + result.post_date : result.link;
  const urlLink = result.link.match(/^http[s]*:\/\//) ? result.link  : `http://${result.link}`;

  return (
    <section>
      <Col xs={2}>
        <img src={ result.image } width="75" height="75" alt="results"/>
      </Col>
      <Col xs={10}>
        <a href={ urlLink } target="_blank" rel="noopener noreferrer">{ result.name }</a>
        <p className="result-link">{ link_text }</p>
        <p><strong>{
            (() =>
              (result.snippet.length > 100) ? result.snippet.substr(0, 100).trim() + '...' : result.snippet
            )()
          }
        </strong></p>
      </Col>
    </section>
  );
};

export default SearchResult;
