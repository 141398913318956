import React from 'react';
import {
  Modal
} from 'react-bootstrap';
import classnames from 'classnames';

class DialogBox extends React.Component{
  state = {
      show: false,
      title: 'Modal Title'
  };

  handleClose = () => {
    this.props.handleClose();
  };

  render() {
    const { show, title, custom_class } = this.props;

    return (
      <Modal
        show={show}
        dialogClassName={classnames(['custom-modal', custom_class])}
      >
        <Modal.Header>
          <Modal.Title>{ title }</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          { this.props.children }
        </Modal.Body>

      </Modal>
    );
  }
}

export default DialogBox;
