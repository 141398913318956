// react components
import React from 'react';
import {
  Grid,
  Row,
  Col
} from 'react-bootstrap';
import BaseSection from '../../../../components/baseSection';
import FloodElement from './FloodElement';
import HailElement from './HailElement';
import TornadoElement from './TornadoElement';
import EarthquakeElement from './EarthquakeElement';

//import DistanceMapComponent from '../../../../components/Display/DistanceMapComponent';
//        <DistanceMapComponent location={location} height="350px" width="100%" />

const WeatherOverview = (props) => {
  const {
    location
  } = props;

  return (
    <BaseSection
      title="Weather Details"
      handleCloseTab={props.handleCloseTab}>

      <Grid className="component-container">

        <Row>
          <Col xs={6}>
            <FloodElement       location={location}/>
          </Col>

          <Col xs={6}>
            <HailElement        location={location}/>
          </Col>
        </Row>

        <Row>
          <Col xs={6}>
            <TornadoElement     location={location}/>
          </Col>

          <Col xs={6}>
            <EarthquakeElement  location={location}/>
          </Col>

        </Row>

      </Grid>
    </BaseSection>
  );
};

export default WeatherOverview;
