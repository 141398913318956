import { updateLocation } from '../../actions/dashboardActions';
import { getLocation } from '../../utils/submissionUtils';
import apiGateway from '../../api/apiGatewayWrapper';


const getByZipcode = (props) => {
  return new Promise((resolve, reject) => {

    const location = getLocation(props.location_id);
    if (location.pop_zipcode && !props.forceRequest) {
      resolve(location.pop_zipcode);

      return;
    }

    return apiGateway.getPopulation(
      { zipcode: props.zipcode }
    ).then((r) => {
      const resp = r.data;

      if (!props.forceRequest) {
        props.dispatch(
            updateLocation(
              props.location_id,
              Object.assign(location, { pop_zipcode: resp }))
        );
      }

      resolve(resp);
    }).catch(e => {
      reject('Failed to load server data');
    });

  });
};

export default getByZipcode;
