// react
import React from 'react';

// tools
import parser from 'parse-address';
import _ from 'lodash';

import {
  Button,
  ButtonGroup
} from 'react-bootstrap';

import ValidationInput from '../../../../components/Input/ValidationInput';

class UseInputModal extends React.Component{
  getAddressComponent = (obj) => {
    const rtn = (obj !== undefined) ? (obj + ' ') : '';

    return rtn;
  };

  constructor (props) {
    super(props);
    const parsed_address = parser.parseLocation(this.props.location);

    this.state = {
      name: this.getAddressComponent(_.trim(this.props.name)),
      name_validation: { status: 'default', message: '' },

      address: this.getAddressComponent(parsed_address.number) + this.getAddressComponent(parsed_address.prefix) + this.getAddressComponent(parsed_address.street) + ' ' + this.getAddressComponent(parsed_address.type),
      address_validation: { status: 'default', message: '' },

      city: this.getAddressComponent(_.trim(parsed_address.city)),
      city_validation: { status: 'default', message: '' },

      state: this.getAddressComponent(_.trim(parsed_address.state)),
      state_validation: { status: 'default', message: '' },

      zipcode: this.getAddressComponent(_.trim(parsed_address.zip)),
      zipcode_validation: { status: 'default', message: '' }
    };
  }

  handleClose = (e) => {
    const validation_fields = [
      { name: 'name' },
      { name: 'address' },
      { name: 'city' },
      { name: 'state' },
      { name: 'zipcode' }
    ];

    let validated = true;

    validation_fields.forEach((field) => {
      const validation_obj = {};
      validation_obj[field.name + '_validation'] = { status: 'default', message: '' };

      if (_.trim(this.state[field.name]) === '') {
        validation_obj[field.name + '_validation'] = { status: 'error', message: 'Please enter a value for the business ' + field.name + '.' };
        validated = false;
      }

      this.setState(validation_obj);
    });

    if (!validated) {
      return;
    }

    this.props.handleClose({
      key: _.trim(this.state.name.replace(' ', '-')),
      name: _.trim(this.state.name),
      address: _.trim(this.state.address),
      city: _.trim(this.state.city),
      state: _.trim(this.state.state),
      zipcode: _.trim(this.state.zipcode),
      source: 'User Input'
    });
  };

  handleCancel = (e) => {
    e.preventDefault();
    this.props.handleCancel();
  };

  handleInput = (params) => {
    const new_state = {};
    new_state[params.name] = params.value;
    this.setState(new_state);
  };

  render () {
    return (
      <section className='useInputModal'>

        <h1>Here is what we could get from your input.</h1>
        <p>Edit as you wish and press use continue.</p>
        <hr/>

        <ValidationInput
          id="name"
          value={this.state.name}
          type="text"
          label="Name"
          validation_status={ this.state.name_validation.status }
          validation_message={ this.state.name_validation.message }
          handleInputChange={this.handleInput}
        />

        <ValidationInput
          id="address"
          value={this.state.address}
          type="text"
          label="Address"
          validation_status={ this.state.address_validation.status }
          validation_message={ this.state.address_validation.message }
          handleInputChange={this.handleInput}
        />

        <ValidationInput
          id="city"
          value={this.state.city}
          type="text"
          label="City"
          validation_status={ this.state.city_validation.status }
          validation_message={ this.state.city_validation.message }
          handleInputChange={this.handleInput}
        />

        <ValidationInput
          id="state"
          value={this.state.state}
          type="text"
          label="State"
          validation_status={ this.state.state_validation.status }
          validation_message={ this.state.state_validation.message }
          handleInputChange={this.handleInput}
        />

        <ValidationInput
          id="zipcode"
          value={this.state.zipcode}
          type="text"
          label="Zipcode"
          validation_status={ this.state.zipcode_validation.status }
          validation_message={ this.state.zipcode_validation.message }
          handleInputChange={this.handleInput}
        />

        <ButtonGroup>
          <Button bsStyle="primary" onClick={this.handleClose}>Use my input</Button>
          <Button bsStyle="info" onClick={this.handleCancel}>Cancel</Button>
        </ButtonGroup>

      </section>
    );
  }
}

export default UseInputModal;
