import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadFromCache } from '../../utils/dataCache';
import PrintPropCrime from './PrintPropCrime';
import PrintWeatherDetails from './PrintWeatherDetails';
import PrintCommonMaps from './PrintCommonMaps';
import PrintModal from './PrintModal';
import PrintNavigationBar from './PrintNavigationBar';
import {
  Grid,
  Row,
  Col,
  Alert,
} from 'react-bootstrap';


// import DistanceMapComponent from '../../components/Display/DistanceMapComponent';
//               <Row>
//                 <Col xs={12} className="printWhole">
//                   <DistanceMapComponent location={location} height="400px" width="500px"/>
//                 </Col>
//               </Row>

class PrintPage extends Component {
  constructor (props) {
    super(props);
    const submission_file = loadFromCache('submission_file');

    this.state = {
      submission_file,
      printModal: true
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount () {
    setTimeout(() => {
      return (
        this.setState({
          ...this.state.selected,
          printModal: false
        })
      );
    }, 3000);
  }

  handleChange = (e) => {
    this.setState({ ...this.state.selected, [e.target.name]: e.target.checked });
  };

  render () {
    return (
        <div>
          <Grid className="component-container">
          <PrintModal showPrintModal={this.state.printModal}/>

          <PrintNavigationBar/>

          {this.state.submission_file.locations.map((location, index) => {
            const address = `${location.address}, ${location.city}, ${location.state} ${location.zipcode}`;
            return (

              <div key={index} className="printHeader">

              <Row>
                <Col xs={12}>
                  <Alert bsStyle="info">
                    <h4><b><u>{location.name}</u></b> - <i>(Submission #: {this.state.submission_file.submission_id})</i></h4><br/>
                      <a href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`}
                         target="_blank"
                         rel="noopener noreferrer"
                      >
                          <p className="address_1">{address}</p>
                      </a>
                  </Alert>
                </Col>
              </Row>
              <Row className="printWhole">
                <Col xs={6}>
                  <PrintPropCrime business={location}/>
                </Col>
                <Col xs={6}>
                  <PrintWeatherDetails business={location}/>
                </Col>
              </Row>
              <PrintCommonMaps location={location}/>
              </div>
            );
          })
        }
        </Grid>

        </div>
    );
  }
}

export default connect()(PrintPage);
