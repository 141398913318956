import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Table,
  Panel
} from 'react-bootstrap';
import getByState from '../../../services/Weather/getByState';
import getAvg from '../../../services/Weather/getAvg';
import getByZipcode from '../../../services/Weather/getByZipcode';

class PrintWeatherDetails extends Component {
  constructor (props) {
    super(props);
    this.state = {
      earthquake: {
        zipcode: '',
        state: '',
        avg: ''
      },
      flood: {
        zipcode: '',
        state: '',
        avg: ''
      },
      hail: {
        zipcode: '',
        state: '',
        avg: ''
      },
      tornado: {
        zipcode: '',
        state: '',
        avg: ''
      }
    };
  }
  componentDidMount () {
    const {
      business
    } = this.props;

    const zipcode = business.zipcode;
    const state = business.state;
    const location_id = business.id;
    const dispatch = this.props.dispatch;

    Promise.all([
      getByZipcode({ zipcode, location_id, dispatch }),
      getByState({ state, location_id, dispatch }),
      getAvg({ location_id, dispatch })
    ])
    .then((resp) => {

      this.setState({
        earthquake: {
          zipcode: (resp[0]) ? resp[0].earthquake_index : 'not available',
          state: (resp[1]) ? resp[1].earthquake_index : 'not available'
        },
        flood: {
          zipcode: (resp[0]) ? Math.round(resp[0].flood_count) : 'not available',
          state: (resp[1]) ? Math.round(resp[1].flood_count) : 'not available'
        },
        hail: {
          zipcode: (resp[0]) ? Math.round(resp[0].hail_count) : 'not available',
          state: (resp[1]) ? Math.round(resp[1].hail_count) : 'not available'
        },
        tornado: {
          zipcode: (resp[0]) ? resp[0].tornado_index : 'not available',
          state: (resp[1]) ? resp[1].tornado_index : 'not available'
        }
      });
    });
  }

  render () {
    const {
      business
    } = this.props;

    const {
      earthquake,
      flood,
      hail,
      tornado
    } = this.state;

    return (
      <div>
        <Panel
          bsStyle="success">
          <Panel.Heading>Weather Details</Panel.Heading>
          <Panel.Body>
          <Table striped bordered condensed hover>
              <thead>
              <tr>
                  <th>By Region</th>
                  <th>Floods</th>
                  <th>Hail</th>
                  <th>Earthquake Index</th>
                  <th>Tornado Index</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                  <td>{ business.zipcode } zip</td>
                  <td>*{ flood.zipcode }</td>
                  <td>*{ hail.zipcode }</td>
                  <td>**{ earthquake.zipcode }</td>
                  <td>**{ tornado.zipcode }</td>
              </tr>
              <tr>
                  <td>{ business.state }</td>
                  <td>*{ flood.state }</td>
                  <td>*{ hail.state }</td>
                  <td>**{ earthquake.state }</td>
                  <td>**{ tornado.state }</td>

              </tr>
              </tbody>
          </Table>
          </Panel.Body>
          <Panel.Footer>* Total events (1950-2010). ** The index value is calculated based on historical events data using USA.com algorithms. A higher index indicates a higher likelyhood of an event occurance.</Panel.Footer>
        </Panel>
        <a className="source" href="/" target="_blank" rel="noopener noreferrer">USA.com</a>
      </div>
    );
  }

}

export default connect()(PrintWeatherDetails);
