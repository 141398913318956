import apiGateway from '../../api/apiGatewayWrapper';

const KeywordSearch = (url) => {

  return apiGateway.getSpiderWordScan(
    { url }
  ).then((r) => {
    const resp = r.data;

    return resp;
  }).catch((error) => {
    return ({ success: false, message: 'Internal Error' });
  });

};


export default KeywordSearch;
