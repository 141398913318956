import React from 'react';
import { connect }  from 'react-redux';

import {
  Button
} from 'react-bootstrap';
import BaseElement from '../../../../../components/baseElement';
import GoogleResult from './GoogleResult';
import search from '../../../../../services/Google/search';

class GoogleElement extends React.Component{
  state = {
      results: [],
      page: 0,
      pageSize: 4
    };

  getSearchTerm = () => {
    const {
      location
    } = this.props;

    return `${location.name} ${location.address} ${location.city} ${location.state} ${location.zipcode}`;
  };

  loadData = () => {
    return new Promise((resolve, reject) => {

      search({
        term: this.getSearchTerm(),
        dispatch: this.props.dispatch,
        location_id: this.props.location.id
      })
      .then((resp) => {

        if (resp.success) {
          this.setState({
            results: resp.results
          });
          resolve();
        } else {
          reject(resp.message);
        }

      });
    });
  };

  handleMoreResults = () => {
    const {
      page,
      pageSize,
      results
    } = this.state;

    this.setState({
      page: ((page + 1) * pageSize >= results.length) ? 0 : (page + 1)
    });
  };

  render () {
    const {
      page,
      pageSize
    }  = this.state;

    return (
      <BaseElement loadData={ this.loadData } title="Google Results" custom_class="results">
        {
          (() => {
            return this.state.results.map((result, idx) => {
              if (idx > page * pageSize && idx < ((page + 1) * pageSize)) {
                return <GoogleResult key={result.title} result={result}/>;
              }else{
                return null;
              }
            });
          })()
        }

        <Button onClick={this.handleMoreResults} bsStyle="info">More results</Button>

        <a
          href={`https://www.google.com/#q=${this.getSearchTerm().replace(/ /g, '+')}`}
          target="_blank"
          rel="noopener noreferrer"
          className="source">
          google.com
        </a>

      </BaseElement>
    );
  }

}

export default connect()(GoogleElement);
