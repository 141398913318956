import React from 'react';

import { connect } from 'react-redux';

import {
  Button
} from 'react-bootstrap';

class ConfirmationModal extends React.Component{

  render () {
    return (
      <section className='confirmationModal'>

        <h2>{this.props.message}</h2>
        <div className="text-center">

          <Button bsStyle="primary" onClick={this.props.handleConfirm}>
            {this.props.confirm_message}
          </Button>

          <Button bsStyle="info" onClick={() => this.props.handleCancel()}>
            {this.props.cancel_message}
          </Button>

        </div>
      </section>
    );
  }

}


export default connect()(ConfirmationModal);
