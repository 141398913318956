import { updateLocation } from '../../actions/dashboardActions';
import { getLocation } from '../../utils/submissionUtils';

const getAvg = (props) => {
  return new Promise((resolve, reject) => {

    const location = getLocation(props.location_id);
    if (location.crime_us && !props.forceRequest) {
      resolve(location.crime_us);
      return;
    }

    const resp = { property_crime: 35.4, violent_crime: 22.7 };

    if (!props.forceRequest) {
      props.dispatch(
        updateLocation(
          props.location_id,
          Object.assign(location, { crime_us: resp }))
      );
    }

    resolve(resp);
  });
};


export default getAvg;
