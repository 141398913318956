import apigClientFactoryNode from 'aws-api-gateway-client';
import auth from '../auth';

const apigClient = apigClientFactoryNode.newClient({
  invokeUrl: process.env.REACT_APP_APIG_INVOKE_URL,
  region: 'us-east-1'
});

const getHeaders = async (queryParams) => {
  let retVal = {};

  const token = await auth.getAccessToken();

  if(!token){
    window.location.reload();
  }

  retVal.headers = {
    Authorization: `Bearer ${token}`
  };

  if (queryParams) {
    retVal.queryParams =  queryParams;
  }

  return retVal;
};

const callApi = (params, path, method, additionalParams, body) => {
  console.log("Calling " + method, path, "Params", additionalParams, "Body:", body, "Path Params: ", params);
  return apigClient.invokeApi(params, path, method, additionalParams, body);
};


export default {
  getBBBBusiness: async (queryParams) => callApi(null, '/search/bbb/business', 'GET', await getHeaders(queryParams)),
  getBBBBusinesses: async (queryParams) => callApi(null, '/search/bbb/businesses', 'GET', await getHeaders(queryParams)),
  getGoogle: async (queryParams) => callApi(null, '/search/google', 'GET', await getHeaders(queryParams)),
  getGoogleGeoCode: async (queryParams) => callApi(null, '/search/google/geocode', 'GET', await getHeaders(queryParams)),
  getGoogleLaw: async (queryParams) => callApi(null, '/search/google/law', 'GET', await getHeaders(queryParams)),
  getGoogleNews: async (queryParams) => callApi(null, '/search/google/news', 'GET', await getHeaders(queryParams)),
  getGooglePlacesBusiness: async (queryParams) => callApi(null, '/search/google/places/business', 'GET', await getHeaders(queryParams)),
  getGooglePlacesBusinesses: async (queryParams) => callApi(null, '/search/google/places/businesses', 'GET', await getHeaders(queryParams)),
  getYellowpagesBusiness: async (queryParams) => callApi(null, '/search/yellowpages/business', 'GET', await getHeaders(queryParams)),
  getYellowpagesBusinesses: async (queryParams) => callApi(null, '/search/yellowpages/businesses', 'GET', await getHeaders(queryParams)),
  getYelpBusiness: async (queryParams) => callApi(null, '/search/yelp/business', 'GET', await getHeaders(queryParams)),
  getYelpBusinesses: async (queryParams) => callApi(null, '/search/yelp/businesses', 'GET', await getHeaders(queryParams)),
  getAllBusinesses: async (queryParams) => callApi(null, '/search/all/businesses', 'GET', await getHeaders(queryParams)),

  getCrime: async (queryParams) => callApi(null, '/data/crime', 'GET', await getHeaders(queryParams)),
  getCrimeState: async (queryParams) => callApi(null, '/data/crime/state', 'GET', await getHeaders(queryParams)),
  getPopulation: async (queryParams) => callApi(null, '/data/population', 'GET', await getHeaders(queryParams)),
  getPopulationState: async (queryParams) => callApi(null, '/data/population/state', 'GET', await getHeaders(queryParams)),
  getWeatherCoastDistance: async (queryParams) => callApi(null, '/data/weather/coastdistance', 'GET', await getHeaders(queryParams)),
  getWeather: async (queryParams) => callApi(null, '/data/weather', 'GET', await getHeaders(queryParams)),
  getWeatherState: async (queryParams) => callApi(null, '/data/weather/state', 'GET', await getHeaders(queryParams)),

  createSubmission: async (request) => callApi(null, '/submissions', 'POST', await getHeaders(), request),
  getSubmissions: async (queryParams) => callApi(null, '/submissions', 'GET', await getHeaders(queryParams)),
  getSubmission: async (id) => callApi(null, `/submissions/${id}`, 'GET', await getHeaders()),
  deleteSubmission: async (id) => callApi(null, `/submissions/${id}`, 'DELETE', await getHeaders()),
  updateSubmission: async (id, request) => callApi(null, `/submissions/${id}`, 'PUT', await getHeaders(), request),

  getSpiderWordScan: async (queryParams) => callApi(null, '/spider/wordscan', 'GET', await getHeaders(queryParams)),
};
