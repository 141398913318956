import React from 'react';
import classnames from 'classnames';
import _ from 'lodash';

class SearchItem extends React.Component{
  handleClick = (business) => {
    this.props.handleSelectItem(business);
  };

  render () {
    const business = this.props.business;

    return <div className={classnames('business-item', (business.active) ? 'active' : '')}
      onClick={
        (e) => {
          if (e.target.getAttribute('class') !== 'button')
            this.handleClick(business);
        }
      }>

      <h4>{business.name}</h4>
      <p>{business.address}</p>
      <p>{business.city}, {business.state} {business.zipcode}</p>
      <span className="source">Found on {_.capitalize(business.source)}</span>

      <span className="glyphicon glyphicon-ok"></span>
    </div>;
  }
}

export default SearchItem;
