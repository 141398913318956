import React from 'react';
import { connect } from 'react-redux';
import BaseSection from '../../../../components/baseSection';
import LoadingComponent from '../../../../components/Display/LoadingComponent';
import ErrorComponent from '../../../../components/Input/ErrorComponent';
import SearchInput from '../../../../components/Input/SearchInputComponent';
import GoogleStreetView from "../../../../components/GoogleStreetView";

class StreetView extends React.Component{
  constructor (props) {
    super(props);
    const {
      address,
      city,
      state,
      zipcode
    } = props.location;

    this.state = {
      inputAddress: `${address} ${city}, ${state} ${zipcode}`,
      streetViewAddress: `${address} ${city}, ${state} ${zipcode}`,
      position: { lat: 0, lng: 0  },
      status: 'loading'
    };
  }

  handleChangeInputAddress = (address) => {
    this.setState({
      inputAddress: address
    });
  };

  handleUpdateStreetViewAddress = (address) => {
    this.setState({
      streetViewAddress: address,
      status: 'display'
    });
  };

  handleResetAddress = () => {
    const {
      address,
      city,
      state,
      zipcode
    } = this.props.location;

    this.handleChangeInputAddress (`${address} ${city}, ${state} ${zipcode}`);
    this.handleUpdateStreetViewAddress (`${address} ${city}, ${state} ${zipcode}`);
  };

  handleError = (msg) => {
    this.setState({status: 'notFound'});
  };

  loadData = () => {
    return new Promise((resolve, reject) => {
      this.setState({
        status: 'display'
      });
      resolve();
    });
  };

  setDisplay = () => {
    switch (this.state.status) {
      case ('loading'):
        return <LoadingComponent/>;
      case ('display'):
        return <GoogleStreetView onError={this.handleError} address={this.state.streetViewAddress}/>;
      case ('notFound'):
      default:
        return <ErrorComponent
          headerMessage={'Street View Not Avalible for this address.'}
          message={'Please refer to Aerial Map or Overview Map as alternatives.'}/>;
    }
  };

  render () {
    return (
      <BaseSection
        loadData={ this.loadData }
        title="Street View"
        handleCloseTab={this.props.handleCloseTab}>

        <SearchInput
          title={ '' }
          input={ this.state.inputAddress }
          button_name="Search"
          className="street_input"
          handleChangeInput={
            this.handleChangeInputAddress
          }

          handleUpdateInput={
            this.handleUpdateStreetViewAddress
          }

          handleResetInput={
            this.handleResetAddress
          }
        />

        <div className="map-area">
          {
            this.setDisplay()
          }
        </div>

      </BaseSection>
    );
  }
}

const mapStateToProps = (state) => {
  return ({
    submission_file: state.dashboard.submission_file
  });
};

export default connect(mapStateToProps)(StreetView);
