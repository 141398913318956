import React from 'react';

const KeywordResultsEmpty = () =>
(
  <div className="error_container">
    <h3>No Keywords Found</h3><p>Try a scanning a different site.</p>
  </div>
);

export default KeywordResultsEmpty;
