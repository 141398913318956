import React from 'react';
import classNames from 'classnames';
import LoadingComponent from './Display/LoadingComponent';
import ErrorComponent from './Display/ErrorComponent';

class BaseElement extends React.Component {
  state = {status: 'loading'};

  componentDidMount () {
    if (!this.props.loadData){
      this.setState({
        status: 'display'
      });

      return;
    }

    this.props.loadData()
      .then(() => {
        this.setState({
          status: 'display'
        });

      })
      .catch((error_message) => {
        this.setState({
          status: 'error',
          error_message
        });

      });
  }

  getDisplay = (status) => {
    switch (this.state.status) {
      case ('loading'):
        return (<LoadingComponent />);
      case ('display'):
        return this.props.children;
      case ('error'):
      default:
        return (<ErrorComponent message={ this.state.error_message }/>);
    }
  };

  render () {
    return (
      <div className={classNames('component', this.props.custom_class) }>
        {
          (() => (this.props.title) ?
            <div>
              <h3>{ this.props.title } <span>{ this.props.subtitle }</span></h3>
              <hr/>
            </div> :
            <div></div>)()
        }

        {
          this.getDisplay(this.state.status)
        }
      </div>
    );
  }

}

export default BaseElement;
