import {
  SHOW_SUBMISSION_MODAL,
  UPDATE_SUBMISSION_FILE,
  CLEAR_SUBMISSION_FILE,
  SHOW_PREVIOUS_SUBMISSIONS
} from '../actions/dashboardActions';

import submission_schema from '../config/submission_file_schema.json';

export function dashboardReducer (state = {submission_file: submission_schema}, action) {
  switch (action.type) {
    case SHOW_SUBMISSION_MODAL:
      return {
        ...state,
        show_submission_modal: action.show_submission_modal
      };

    case UPDATE_SUBMISSION_FILE:
      return {
          ...state,
          submission_file: action.submission_file
      };


    case CLEAR_SUBMISSION_FILE:
      return {
        ...state,
        submission_file: null
      };

    case SHOW_PREVIOUS_SUBMISSIONS:
      return {
        ...state,
        show_submission_data: action.show_submission_data
      };

    default:
      return state;
  }
}
