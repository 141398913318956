import React from 'react';
import {connect} from 'react-redux';
import content from '../../../content';
import {
    Navbar,
    Nav,
    NavItem,
    Button,
    OverlayTrigger,
    Tooltip
} from 'react-bootstrap';

import DialogBox from '../../../components/Modal/DialogBox';
import ConfirmationModal from '../../../components/Modal/ConfirmationModal';
import {showSubmissionModal} from '../../../actions/dashboardActions';
import {withAuth} from '@okta/okta-react';
import history from '../../../history';
import {Link} from "react-router-dom";
import storageWrapper from "../../../api/storageWrapper";

class NavigationBar extends React.Component {
    state = {
        show_confirmation_modal: false
    };

    handleNewSubmission = () => {
        this.props.showSubmissionModal(true);
    };

    handleLogoutConfirmation = () => {
        storageWrapper.clearState('userProfile');
        storageWrapper.clearState('accessToken');

        this.props.auth.logout('/');

        this.setState({
            show_confirmation_modal: false
        });
    };

    handleCancelConfirmation = () => {
        this.setState({
            show_confirmation_modal: false
        });
    };

    handlePrint = () => {
        history.push('/print');
    };

    handleLogout = () => {
        this.setState({
            show_confirmation_modal: true
        });
    };

    render() {
        let exportTooltip;
        if (this.props.disable_export) {
            exportTooltip = (
                <Tooltip id="tooltip">Please select address before exporting.</Tooltip>
            );

        } else {
            exportTooltip = (
                <Tooltip id="tooltip">Click to create Printable/PDF version of data.</Tooltip>
            );
        }

        return (
            <Navbar className="nav">
                <Navbar.Header>
                    <Navbar.Brand>
                        <Link to="/">
              <span className="title">
                <b>{content.title}</b>
              </span>
                            <span className="logo">Argo Logo</span>

                        </Link>
                    </Navbar.Brand>

                    <Navbar.Toggle/>

                </Navbar.Header>
                {
                    (() => {
                        return (
                            <div>
                                <Navbar.Collapse>
                                    <Nav pullRight>

                                        <NavItem eventKey={1} href="#">
                                            <Button
                                                bsStyle="info"
                                                onClick={this.handleNewSubmission}>
                                                New Submission
                                            </Button>
                                        </NavItem>

                                        <NavItem eventKey={2}>
                                            <OverlayTrigger placement="bottom" overlay={exportTooltip}>
                                                <Button
                                                    bsStyle="primary"
                                                    disabled={this.props.disable_export}
                                                    onClick={this.handlePrint}
                                                >
                                                    Export Data
                                                </Button>
                                            </OverlayTrigger>
                                        </NavItem>

                                        <NavItem eventKey={2}>
                                            <Button
                                                bsStyle="danger"
                                                onClick={this.handleLogout}>
                                                Log out
                                            </Button>
                                        </NavItem>

                                    </Nav>
                                </Navbar.Collapse>

                                <Navbar.Text pullRight className="submission-number">
                                    <label>Submission</label>
                                    <span>{" " + (this.props.submission_file.submission_id || '')}</span>
                                </Navbar.Text>
                            </div>
                        );
                    })()
                }

                <DialogBox
                    show={this.state.show_confirmation_modal}>
                    <ConfirmationModal
                        message="Are you sure you want to Logout?"
                        confirm_message="Log out"
                        cancel_message="Cancel"
                        handleConfirm={this.handleLogoutConfirmation}
                        handleCancel={this.handleCancelConfirmation}
                    />
                </DialogBox>

            </Navbar>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        submission_file: state.dashboard.submission_file,
        disable_export: state.dashboard.submission_file.locations.length === 0
    };
};

export default connect(mapStateToProps, {showSubmissionModal})(withAuth(NavigationBar));
