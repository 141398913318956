import { updateLocation } from '../../actions/dashboardActions';
import { getLocation } from '../../utils/submissionUtils';
import apiGateway from '../../api/apiGatewayWrapper';

const getByZipcode = (props) => {
  return new Promise((resolve, reject) => {

    const location = getLocation(props.location_id);
    if (location.weather_zipcode && !props.forceRequest) {
      resolve(location.weather_zipcode);
      return;
    }

    apiGateway.getWeather(
      { zipcode: props.zipcode }
    ).then((r) => {
      const resp = r.data;

      if (!props.forceRequest) {
        props.dispatch(
          updateLocation(
            props.location_id,
            Object.assign(location, { weather_zipcode: resp }))
        );
      }

      resolve(resp);
    });
  });
};

export default getByZipcode;
