import React from 'react';

class FemaItem extends React.Component{
  render () {
    const { position } = this.props;

    const extent = (position.longitude - 0.00215) + ',' +  (position.latitude - 0.002) + ',' +  (position.longitude + 0.00215) + ',' + (position.latitude + 0.002);
    const marker = position.longitude + ';' +  position.latitude;
    const src = 'https://www.arcgis.com/apps/Embed/index.html?webmap=42328623e5eb452e91dbb05c24a560fe&amp;&extent=' + extent + '&zoom=true&scale=true&legendlayers=true&marker=' + marker;

    const jsx = React.createElement('iframe', { className: 'fema', src: src, width: '100%', height: '500', position: 'relative' });

    return jsx;
  }

}

export default FemaItem;
